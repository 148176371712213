import cc from 'currency-codes';
import countries from 'country-json/src/country-by-abbreviation.json';
import { FilterConf, FilterType } from 'types';
import resources from 'store/resources';

const METADATA_GROUP = 'Metadata Filters';
const BASIC_GROUP = 'Basic Filters';
const BREACHES_GROUP = 'Breaches Filters';

const searchFilters: FilterConf = new Map([
  [
    'search_simple_query_string',
    {
      label: 'Search',
      type: FilterType.Text,
      hidden: true,
      group: BASIC_GROUP,
    },
  ],
  [
    'reviewed',
    {
      enabled: true,
      hidden: true,
      label: 'Document Reviewed',
      type: FilterType.Text,
    },
  ],
  [
    'data_source_id',
    {
      label: 'Data Source',
      type: FilterType.Autocomplete,
      config: {
        resource: resources.DATA_SOURCES,
        optionValue: 'id',
        optionText: 'name',
      },
      enabled: true,
      many: true,
      description:
        'The underlying regulator/news site/legal firm from which the data has been sourced.',
      group: BASIC_GROUP,
    },
  ],
  [
    'doc_types_id',
    {
      label: 'Document Type',
      type: FilterType.Autocomplete,
      enabled: true,
      many: true,
      config: {
        resource: resources.DOCUMENT_TYPE,
        optionValue: 'id',
        optionText: 'doc_type',
      },
      description:
        'The different types of information held within the system for example: regulations, news, final notices or primary legislation.',
      group: METADATA_GROUP,
    },
  ],
  [
    'sanctions',
    {
      label: 'Sanctions',
      type: FilterType.Autocomplete,
      many: true,
      config: {
        resource: resources.SANCTIONS,
        optionValue: 'id',
        optionText: 'sanction',
      },
      description: 'Type of sanctions imposed by the courts.',
      group: METADATA_GROUP,
    },
  ],
  [
    'recipient_type_id',
    {
      label: 'Recipient Type',
      type: FilterType.Autocomplete,
      config: {
        resource: resources.RECIPIENT_TYPES,
        optionValue: 'id',
        optionText: 'recipient_type',
      },
      many: true,
      description: 'Recipient type filter.',
      group: BASIC_GROUP,
    },
  ],
  [
    'geographical_extent',
    {
      label: 'Geographical Extent',
      type: FilterType.Autocomplete,
      enabled: true,
      many: true,
      config: {
        resource: resources.GEOGRAPHICAL_EXTENTS,
        optionValue: 'code',
        optionText: 'name',
      },
      description:
        'This is the geographical area to which a piece of legislation (or part/section of a piece of legislation) applies.',
      group: BASIC_GROUP,
    },
  ],
  [
    'tags',
    {
      label: 'Tags',
      type: FilterType.Text,
      enabled: true,
      many: true,
      description:
        'Common keywords or lower level topics assigned at article level for ease of navigation.',
      group: METADATA_GROUP,
    },
  ],
  [
    'themes',
    {
      label: 'Themes',
      type: FilterType.Text,
      enabled: true,
      many: true,
      description:
        'Common themes to make cross referencing easier, for example Anti-Money Laundering.',
      group: METADATA_GROUP,
    },
  ],
  [
    'breaches_primary',
    {
      type: FilterType.Text,
      many: true,
      label: 'Breaches Primary',
      description: 'Breaches primary filter.',
      group: METADATA_GROUP,
    },
  ],
  [
    'breaches_secondary',
    {
      hidden: true,
      type: FilterType.Text,
      many: true,
      label: 'Breaches Secondary',
      description: 'Breaches secondary filter.',
      group: BREACHES_GROUP,
    },
  ],
  [
    'breaches_supporting',
    {
      hidden: true,
      type: FilterType.Text,
      many: true,
      label: 'Breaches Supporting',
      description: 'Breaches supporting filter.',
      group: BREACHES_GROUP,
    },
  ],
  [
    'breaches_observed',
    {
      hidden: true,
      type: FilterType.Text,
      many: true,
      label: 'Breaches Observed',
      description: 'Breaches observed filter.',
      group: BREACHES_GROUP,
    },
  ],
  [
    'industry',
    {
      label: 'Industry',
      type: FilterType.Text,
      enabled: true,
      many: true,
      choices: [
        { id: 1, name: 'Investment Firm' },
        { id: 2, name: 'Building Society' },
        { id: 3, name: 'Credit Union/Friendly Society' },
        { id: 4, name: 'Securities Brokerage' },
        { id: 5, name: 'Independent Financial Advisor (IFA)' },
        { id: 6, name: 'Other' },
        { id: 7, name: 'Professional Service Provider' },
        { id: 8, name: 'Outsourced Service Provider' },
      ],
      config: {
        optionValue: 'name',
        optionText: 'name',
      },
      description:
        'A business sector as defined under SIC, for example Mining or Financial Services.',
      group: BASIC_GROUP,
    },
  ],
  // regulator: {
  //   label: 'Regulator',
  //   type: FilterType.Text,
  //   enabled: true,
  //   many: true,
  // },
  [
    'government_department_id',
    {
      label: 'Government Department',
      type: FilterType.Autocomplete,
      config: {
        resource: resources.GOVERNMENT_DEPARTMENTS,
        optionValue: 'id',
        optionText: 'government_department',
      },
      enabled: false,
      many: true,
      description:
        'The publishing Department that is responsible for the creation of the text.',
      group: BASIC_GROUP,
    },
  ],
  [
    'publication_date',
    {
      label: 'Publication Date',
      type: FilterType.DateRangePicker,
      enabled: true,
      description: 'The date upon which the text was published.',
      group: METADATA_GROUP,
    },
  ],
  [
    'case_period_from',
    {
      label: 'Relevant Period Start',
      type: FilterType.DateInput,
      enabled: true,
      description:
        'Starting point of the period when the document is relevant.',
      group: METADATA_GROUP,
    },
  ],
  [
    'case_period_to',
    {
      label: 'Relevant Period End',
      type: FilterType.DateInput,
      enabled: true,
      description: 'Ending point of the period when the document is relevant.',
      group: METADATA_GROUP,
    },
  ],
  [
    'commencement_date',
    {
      enabled: false,
      label: 'Commencement Date',
      type: FilterType.DateRangePicker,
      description: 'The date upon which the regulations become enforceable.',
      group: METADATA_GROUP,
    },
  ],
  [
    'origin__contains',
    {
      label: 'Origin',
      type: FilterType.Autocomplete,
      enabled: true,
      many: true,
      choices: countries,
      config: {
        optionValue: 'abbreviation',
        optionText: 'country',
      },
      group: BASIC_GROUP,
      description: 'The region or country from which the document was issued.',
    },
  ],
  [
    'fine_currency',
    {
      many: true,
      enabled: false,
      label: 'Fine Currency',
      type: FilterType.Autocomplete,
      choices: cc.codes(),
      format: (option: string) => option,
      description: 'The base currency of the fine applied.',
      group: METADATA_GROUP,
    },
  ],
  [
    'fine_amount',
    {
      label: 'Fine Amount',
      type: FilterType.Range,
      enabled: false,
      description:
        'The level of a financial penalty applied to an individual or organisation.',
      group: METADATA_GROUP,
    },
  ],
]);

export default searchFilters;
