import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppliedFilters, FilterConf } from 'types';
// import { NOT_FOUND } from 'contstants';
// import NotFound from 'components/NotFound';
import useListController from './useListController';
import { Query } from './useListParams';

type Item = Record<string, any>;

interface Props extends RouteComponentProps {
  isStatic?: boolean;
  baseFilters?: AppliedFilters;
  errors: Record<string, string[]>;
  filtersConf: FilterConf;
  isLoading: boolean;
  isDoFetch: (values: Query) => boolean;
  updateInList: (item: Item) => void;
  count: number;
  perPage: number;
  component: React.ComponentType<any>;
  fetch: (values: any) => any;
}

const ListController = React.memo(
  ({
    filtersConf,
    baseFilters,
    history,
    location,
    count,
    perPage,
    isLoading,
    errors,
    fetch,
    isStatic,
    isDoFetch,
    updateInList,
    component: Component,
    ...restProps
  }: Props) => {
    const {
      updateSort,
      updateFilters,
      updatePaginate,
      filters,
      pagination,
      sort,
      loading,
    } = useListController({
      isLoading,
      isStatic,
      isDoFetch,
      filtersConf,
      baseFilters,
      fetch,
      history,
      location,
      count,
      perPage,
      ...restProps,
    });
    // if (errors['http_error'] && errors['http_error'][0] === NOT_FOUND) {
    //   return <NotFound />;
    // }
    return (
      <Component
        {...restProps}
        filters={filters}
        filtersConf={filtersConf}
        sort={sort}
        pagination={pagination}
        count={count}
        isLoading={loading}
        onFilterChange={updateFilters}
        onSortChange={updateSort}
        onPageChange={updatePaginate}
        updateInPlace={updateInList}
        history={history}
        location={location}
        errors={errors}
      />
    );
  },
);

export default withRouter(ListController);
