import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { Location } from 'history';
import qs from 'query-string';
import { AppliedFilters, FilterConf, Pagination, Parser, Sort } from 'types';

interface ParsedData {
  filters: AppliedFilters;
  sort: Array<Sort>;
  pagination: Pagination;
}

const PER_PAGE = 25;

const FILTER_REGEXP = /(\w+)\[(\w+)\]/;

const getValue = (value: any, parser: Parser | undefined) => {
  if (parser) return parser(value);
  return isNaN(value) ? value : parseInt(value);
};

const ensureArray = (value: string | Array<string>) => {
  if (value) {
    return isArray(value) ? value : [value];
  }
  return [];
};

const parseRoute = (
  location: Location,
  filtersConf: FilterConf,
  defaultPerPage: number = PER_PAGE,
): ParsedData => {
  const parsedQs = qs.parse(location.search) as Record<string, any>;
  const { sort, page, per_page: perPage, ...filters } = parsedQs;
  const parsedPagination = {
    page: page ? parseInt(page) : 1,
    perPage: perPage ? parseInt(perPage) : defaultPerPage,
  };

  const parsedFilters = isEmpty(filters)
    ? {}
    : Object.keys(filters).reduce((resFilters, filterName) => {
        const result = FILTER_REGEXP.exec(filterName);
        if (result) {
          const [matchedStr, parsedFilterName, parsedFilterProp] = result;

          if (!filtersConf.has(parsedFilterName)) return resFilters;

          const value = get(resFilters, parsedFilterName, {});
          value[parsedFilterProp] = filters[matchedStr];
          return {
            ...resFilters,
            [parsedFilterName]: value,
          };
        }
        if (!filtersConf.has(filterName)) return resFilters;

        const parser = filtersConf.get(filterName)!.parser;
        const value =
          filtersConf.get(filterName)!.many && !isArray(filters[filterName])
            ? [filters[filterName]]
            : filters[filterName];
        return {
          ...resFilters,
          [filterName]: filtersConf.get(filterName)!.many
            ? value.map((value: any) => getValue(value, parser))
            : getValue(value, parser),
        };
      }, {});

  return {
    pagination: parsedPagination,
    filters: parsedFilters,
    sort: ensureArray(sort).map((sortItem: string) => ({
      order: sortItem.indexOf('-') === 0 ? 'desc' : 'asc',
      field: sortItem.indexOf('-') === 0 ? sortItem.slice(1) : sortItem,
    })),
  };
};

export default parseRoute;
