import { Empty, PageResponse, request, ResponseData } from 'services/request';
import { PolicyMapping } from 'types';

interface Payload {
  domain: string;
  policy: string;
}

const list = (payload: Payload, token: string) =>
  request<Empty, PolicyMapping, Empty>(
    'wm-objects/document-mapping/',
    {
      method: 'POST',
      payload: {
        policy: payload.policy,
        domain: payload.domain,
      },
    },
    token,
  ).then(
    ({ response, error }) =>
      ({
        error,
        response: {
          ...response,
          data: {
            results: response?.data || ([] as PolicyMapping[]),
            count: response?.data?.length || 0,
          },
        },
      } as ResponseData<PageResponse<PolicyMapping>>),
  );

const policyMappingServices = {
  list,
};

export default policyMappingServices;
