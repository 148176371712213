import React from 'react';
import { connect } from 'react-redux';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { ReducerState } from 'store/reducers';
import { drawerSelectors } from 'store/selectors';
import { drawerActions } from 'store/actions';
import DrawerHeader, {
  DrawerHeaderProps,
} from 'components/LeftSideDrawer/DrawerHeader';
import Grid from 'components/Grid';

const DrawerContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5),
  flex: 1,
}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 10,
  '& .MuiDrawer-paperAnchorRight': {
    width: '50vw',
  },
}));

const ContentContainer = styled(Grid)(() => ({
  flex: 1,
  height: 'calc(100vh - 130px)',
}));

export interface LeftSideDrawerProps {
  isOpen: boolean;
  title?: string;
  header?: React.ComponentType<DrawerHeaderProps> | false;
  content: React.ReactElement;
  onClose: () => any;
}

const LeftSideDrawer = ({
  isOpen,
  title,
  header: Header = DrawerHeader,
  content,
  onClose,
}: LeftSideDrawerProps) => (
  <Drawer anchor="right" open={isOpen} onClose={onClose}>
    <DrawerContainer container direction="column">
      {Header && title && <Header title={title} close={onClose} />}
      <ContentContainer item>{content}</ContentContainer>
    </DrawerContainer>
  </Drawer>
);

export default connect(
  (state: ReducerState) => ({
    isOpen: drawerSelectors.isOpen(state),
    title: drawerSelectors.title(state),
    header: drawerSelectors.header(state),
    content: drawerSelectors.content(state),
  }),
  (dispatch) => ({
    onClose: () => dispatch(drawerActions.hideDrawer()),
  }),
)(LeftSideDrawer);
