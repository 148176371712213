import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { crudActions } from 'store/actions';
import { ReducerState } from 'store/reducers';
import { generateResourceSelectors } from 'store/selectors/utils';
import { AppliedFilters, BaseResource, FilterConf } from 'types';
import DetailController, { DetailControllerProps } from './DetailController';

interface ExtraProps {
  id?: string | number;
}

export interface DetailComponentProps<T> {
  filtersConf: FilterConf;
  filters: AppliedFilters;
  baseFilters?: AppliedFilters;
  isLoading: boolean;
  currentItem: T;
  errors: Record<string, string[]>;
  onUpdate: (values: Partial<T>) => any;
  refresh: () => void;
  remove: (
    onSuccess?: Record<string, any>,
    onFailure?: Record<string, any>,
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const withDetailController = <
  T extends BaseResource,
  Props = DetailComponentProps<T>,
>(
  resourceName: string,
  onSuccess?: any,
) => {
  return (Component: React.ComponentType<Props & ExtraProps>) =>
    compose(
      withRouter,
      connect(
        (
          state: ReducerState,
          props: RouteComponentProps<{ id: string }> & ExtraProps,
        ) => {
          const resourceSelectors = generateResourceSelectors(resourceName);
          let id = (props.id || props.match.params.id).toString();
          if (id.indexOf('?') !== -1) {
            id = id.split('?')[0];
          }
          return {
            ...Object.keys(resourceSelectors).reduce(
              (selectors, selectorName) => {
                return {
                  ...selectors,
                  [selectorName]: resourceSelectors[selectorName](state),
                };
              },
              {
                ...props,
                id,
              },
            ),
          };
        },
        (dispatch) => ({
          retrieve: (args: any) =>
            dispatch(
              crudActions.retrieve({
                ...args,
                meta: { resource: resourceName, ...args.meta },
              }),
            ),
          update: (values: Partial<T>, partial: boolean) =>
            dispatch(
              crudActions.update({
                ...values,
                meta: {
                  partial,
                  onSuccess,
                  resource: resourceName,
                  ...values.meta,
                },
              }),
            ),
          remove: (
            values: Partial<T>,
            onSuccess = undefined,
            onFailure = undefined,
          ) =>
            dispatch(
              crudActions.remove({
                ...values,
                meta: {
                  onSuccess,
                  onFailure,
                  resource: resourceName,
                  ...values.meta,
                },
              }),
            ),
        }),
      ),
    )((props: Omit<DetailControllerProps, 'component'>) => (
      <DetailController {...props} component={Component} />
    )) as React.ComponentType<
      Partial<Pick<DetailControllerProps, 'baseFilters' | 'currentItem' | 'id'>>
    >;
};

export default withDetailController;
