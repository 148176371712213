import MuiCalendarPicker, {
  CalendarPickerProps,
} from '@mui/lab/CalendarPicker';
import { styled, Theme } from '@mui/material/styles';

const CalendarPicker = styled(MuiCalendarPicker, {
  overridesResolver: (props, styles) => Object.keys(styles),
})<CalendarPickerProps<unknown>>(({ theme }: { theme: Theme }) => ({
  '.MuiTypography-root': {
    color: theme.palette.text.primary,
  },
}));

export default CalendarPicker;
