import React from 'react';
import { StyledComponent } from '@mui/styles';
import { alpha, Theme, styled } from '@mui/material/styles';
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl';
import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';
import {
  ControllerRenderProps,
  ControllerFieldState as InputState,
} from 'react-hook-form';

import Grid from 'components/Grid';

export interface SwitchProps extends Omit<MuiSwitchProps, 'color'> {
  color?:
    | 'info'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'gray'
    | 'primary';
}

const Switch = styled(MuiSwitch, {
  shouldForwardProp: (propName: string) => propName !== 'color',
})<SwitchProps>(
  ({ theme, checked, color, disabled }: SwitchProps & { theme: Theme }) => {
    const variant = checked ? 'default' : 'outlined';
    return {
      width: 72,
      height: 41,
      padding: theme.spacing(1, 1, 1.125, 1),
      display: 'flex',
      ...(disabled && {
        cursor: 'not-allowed',
      }),
      '& .MuiSwitch-switchBase': {
        position: 'absolute',
        '&.Mui-checked': {
          transform: 'translateX(32px)',
        },
        '&:focus': {
          backgroundColor: alpha(theme.palette.common.black, 0.04),
        },
      },
      '& .Mui-checked': {
        transition: theme.transitions.create(['transform', 'left'], {
          duration: theme.transitions.duration.shortest,
        }),
        ...(variant === 'outlined' && {
          ...(color === 'info' && {
            color: `${theme.palette.text.disabled} !important`,
          }),
          ...(color === 'secondary' && {
            color: `${theme.palette.secondary.main} !important`,
          }),
          ...(color === 'error' && {
            color: `${theme.palette.error.main} !important`,
          }),
          ...(color === 'warning' && {
            color: `${theme.palette.warning.main} !important`,
          }),
          ...(color === 'success' && {
            color: `${theme.palette.success.main} !important`,
          }),
          ...(color === 'gray' && {
            color: `${theme.palette.text.primary} !important`,
          }),
          ...(color === 'primary' && {
            color: `${theme.palette.primary.main} !important`,
          }),
        }),
        '&.Mui-disabled': {
          '& .MuiSwitch-thumb': {
            color: `${theme.palette.grey.A100}`,
          },
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: 40,
        opacity: '1 !important',
        transition: theme.transitions.create(['background-color', 'border'], {
          duration: theme.transitions.duration.shortest,
        }),
        ...(variant === 'default' && {
          ...(color === 'info' && {
            backgroundColor: `${theme.palette.text.disabled} !important`,
          }),
          ...(color === 'secondary' && {
            backgroundColor: `${theme.palette.secondary.main} !important`,
          }),
          ...(color === 'error' && {
            backgroundColor: `${theme.palette.error.main} !important`,
          }),
          ...(color === 'warning' && {
            backgroundColor: `${theme.palette.warning.main} !important`,
          }),
          ...(color === 'success' && {
            backgroundColor: `${theme.palette.success.main} !important`,
          }),
          ...(color === 'gray' && {
            backgroundColor: `${theme.palette.text.primary} !important`,
          }),
          ...(color === 'primary' && {
            backgroundColor: `${theme.palette.primary.main} !important`,
          }),
        }),
        ...(variant === 'outlined' && {
          backgroundColor: 'transparent !important',
          border: `1px solid ${theme.palette.text.disabled}`,
          ...(color === 'info' && {
            borderColor: theme.palette.text.disabled,
          }),
          ...(color === 'secondary' && {
            borderColor: theme.palette.secondary.main,
          }),
          ...(color === 'error' && {
            borderColor: theme.palette.error.main,
          }),
          ...(color === 'warning' && {
            borderColor: theme.palette.warning.main,
          }),
          ...(color === 'success' && {
            borderColor: theme.palette.success.main,
          }),
          ...(color === 'gray' && {
            borderColor: theme.palette.text.primary,
          }),
          ...(color === 'primary' && {
            borderColor: theme.palette.primary.main,
          }),
        }),
      },
      '& .MuiSwitch-thumb': {
        width: 22,
        height: 22,
        color: `${theme.palette.common.white}`,
        boxShadow: '0px 1px 2px #00000029',
        transition: theme.transitions.create('color', {
          duration: theme.transitions.duration.shortest,
        }),
        ...(!checked && {
          ...(color === 'info' && {
            color: `${theme.palette.text.disabled}`,
          }),
          ...(color === 'secondary' && {
            color: `${theme.palette.secondary.main}`,
          }),
          ...(color === 'error' && {
            color: `${theme.palette.error.main}`,
          }),
          ...(color === 'warning' && {
            color: `${theme.palette.warning.main}`,
          }),
          ...(color === 'success' && {
            color: `${theme.palette.success.main}`,
          }),
          ...(color === 'gray' && {
            color: `${theme.palette.text.primary}`,
          }),
          ...(color === 'primary' && {
            color: `${theme.palette.primary.main}`,
          }),
        }),
      },
      '& .Mui-disabled': {
        cursor: 'not-allowed',
        color: `${theme.palette.grey.A100}`,
      },
    };
  },
) as StyledComponent<SwitchProps>;

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  spread?: boolean;
}

const FormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: (propName: string) => propName !== 'spread',
})<FormControlLabelProps>(({ theme, spread }) => ({
  ...(spread && {
    width: '100%',
    justifyContent: 'space-between',
    margin: 0,
  }),
  '& .MuiFormControlLabel-label': {
    color: theme.palette.warning.contrastText,
    flex: 1,
    '&.Mui-disabled': {
      color: theme.palette.warning.contrastText,
    },
  },
}));

export interface SwitchInputProps extends SwitchProps {
  field: ControllerRenderProps;
  meta?: InputState;
  spread?: boolean;
  startIcon?: React.ReactNode;
  margin?: FormControlProps['margin'];
  [prop: string]: any;
}

const SwitchInput = ({
  field,
  name,
  disabled = false,
  startIcon,
  spread,
  margin = 'normal',
  color = 'primary',
  labelPlacement,
  label,
}: SwitchInputProps) => {
  const onChange = React.useCallback(
    (evt, checked) => {
      field.onChange(checked);
    },
    [field],
  );
  return (
    <MuiFormControl fullWidth margin={margin}>
      <Grid container alignItems="flex-start">
        {startIcon && (
          <Grid item actualSize sx={{ paddingRight: 2 }}>
            {startIcon}
          </Grid>
        )}
        <Grid xs item sx={{ paddingRight: 1 }}>
          <FormControlLabel
            label={label}
            spread={spread}
            labelPlacement={labelPlacement}
            disabled={disabled}
            checked={Boolean(field.value)}
            htmlFor={name}
            control={
              <Switch
                id={name}
                {...field}
                color={color}
                onChange={onChange}
                disabled={disabled}
                checked={Boolean(field.value)}
              />
            }
          />
        </Grid>
      </Grid>
    </MuiFormControl>
  );
};

export default SwitchInput;
