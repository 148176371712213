import React from 'react';
import { BaseResource } from 'types';
import { connect } from 'react-redux';
import CreateController from './CreateController';
import { ReducerState } from 'store/reducers';
import { crudActions } from 'store/actions';
import { generateResourceSelectors } from 'store/selectors/utils';

export interface CreateComponentProps<T> {
  isLoading: boolean;
  isSubmitting: boolean;
  onCreate: (values: Partial<T>) => any;
  errors: Record<string, string[]>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const withCreateController = <T extends BaseResource>(
  resourceName: string,
  onSuccess?: any,
) => {
  return (Component: React.ComponentType<CreateComponentProps<T>>) =>
    connect(
      (state: ReducerState) => {
        const resourceSelectors = generateResourceSelectors(resourceName);
        return {
          errors: resourceSelectors.errors(state),
          isLoading: resourceSelectors.isLoading(state),
          isSubmitting: resourceSelectors.isSubmitting(state),
        };
      },
      (dispatch) => ({
        create: (payload: Record<string, any>) =>
          dispatch(
            crudActions.create({
              ...payload,
              meta: {
                resource: resourceName,
                onSuccess,
                ...payload.meta,
              },
            }),
          ),
      }),
    )((props: any) => <CreateController {...props} component={Component} />);
};

export default withCreateController;
