import { JWTToken, NavigationGroup, NavigationItem } from 'types';
import { ReactComponent as BookmarkIcon } from 'assets/bookmark.svg';
import { ReactComponent as CommentIcon } from 'assets/notes_locked.svg';
import { ReactComponent as DashboardIcon } from 'assets/dashboard.svg';
import { ReactComponent as DocumentIcon } from 'assets/document.svg';
import { ReactComponent as ExplorerIcon } from 'assets/explorer.svg';
import { ReactComponent as FAQIcon } from 'assets/faq.svg';
import { ReactComponent as TaskIcon } from 'assets/tasks.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as StarIcon } from 'assets/favourites_locked.svg';
import { ReactComponent as FireIcon } from 'assets/fire_locked.svg';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import { ReactComponent as WorkflowsIcon } from 'assets/workflows.svg';
import { ReactComponent as OrganisationIcon } from 'assets/company.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';
import { ReactComponent as GroupsIcon } from 'assets/user_group.svg';
import { ReactComponent as SourcesIcon } from 'assets/sources.svg';
import { ReactComponent as WebhooksIcon } from 'assets/webhooks.svg';
import { ReactComponent as PolicyIcon } from 'assets/policyMapping.svg';
import { ReactComponent as LicenseIcon } from 'assets/license.svg';

const getNavigationForUser = (jwtToken: JWTToken, email: string) => {
  // const userEnabledFeatures = Object.fromEntries(
  //   jwtToken.payload.permissions.map((featureFlag) => [featureFlag, true]),
  // );

  const isWaymarkStaff =
    jwtToken.payload.is_superuser || jwtToken.payload.is_staff;
  const bottomNavGroup: NavigationGroup = [
    {
      title: 'FAQ',
      icon: FAQIcon,
      link: 'https://docs.waymark.tech',
    },
    {
      title: 'Support',
      icon: SupportIcon,
      anchor: 'intercom',
    },
  ];

  if (isWaymarkStaff) {
    bottomNavGroup.splice(
      0,
      0,
      {
        title: 'Webhooks',
        icon: WebhooksIcon,
        link: '/webhooks/',
      },
      {
        title: 'Policy mapping',
        icon: PolicyIcon,
        link: '/policy-mapping/',
      },
    );
  }

  const baseTopNavGroups: Array<NavigationGroup> = [
    [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        link: '/dashboard',
      },
    ],
  ];

  if (isWaymarkStaff) {
    baseTopNavGroups[0].push({
      title: 'Upload',
      icon: DashboardIcon,
      link: '/upload',
    });
  }

  if (jwtToken.payload.file_uploads_access_only && !isWaymarkStaff) {
    return {
      topNavGroups: [
        [
          {
            title: 'Upload',
            icon: DashboardIcon,
            link: '/upload',
          },
        ],
      ],
      bottomNavGroups: [bottomNavGroup],
    };
  }

  if (isWaymarkStaff) {
    baseTopNavGroups.push([
      {
        title: 'Organisations',
        icon: OrganisationIcon,
        link: '/organisations/',
      },
      {
        title: 'Groups',
        icon: GroupsIcon,
        link: '/groups/',
      },
      {
        title: 'Users',
        icon: UserIcon,
        link: '/users/',
      },
    ]);
  }

  baseTopNavGroups.push([
    {
      title: 'Document library',
      icon: DocumentIcon,
      link: '/folders',
    },
    {
      title: 'Search',
      icon: ExplorerIcon,
      link: '/search',
    },
    {
      title: 'Saved searches',
      icon: BookmarkIcon,
      link: '/saved-searches',
    },
  ]);

  if (jwtToken.payload.are_workflows_allowed || isWaymarkStaff) {
    const middleNavGroup: Array<NavigationItem> = [
      {
        title: 'Workflows',
        icon: WorkflowsIcon,
        link: '/workflows',
      },
      {
        title: 'Workflow Report',
        icon: WorkflowsIcon,
        link: '/workflow-report',
      },
      {
        title: 'Tasks',
        icon: TaskIcon,
        link: '/tasks',
      },
      {
        title: 'Notes',
        icon: CommentIcon,
      },
      {
        title: 'Favourites',
        icon: StarIcon,
      },
      {
        title: 'Trends and analytics',
        icon: FireIcon,
        // link: '/pivot-table',
      },
      // {
      //   title: 'Training',
      //   icon: TrainingIcon,
      //   subItems: [
      //     {
      //       title: 'Scanner',
      //       link: '#',
      //     },
      //     {
      //       title: 'Reader',
      //       link: '#',
      //     },
      //   ],
      // },
    ];

    baseTopNavGroups.push(middleNavGroup);
  }

  if (jwtToken.payload.is_org_admin) {
    bottomNavGroup.unshift({
      title: 'Settings',
      icon: SettingsIcon,
      nestedNav: {
        topNavGroups: [
          [
            {
              title: 'Organisation',
              icon: OrganisationIcon,
              link: '/organisation/',
            },
            {
              title: 'Organisation Groups',
              icon: GroupsIcon,
              link: '/organisation/groups/',
            },
            {
              title: 'Organisation Users',
              icon: UserIcon,
              link: '/organisation/users/',
            },
            {
              title: 'Data Sources',
              icon: SourcesIcon,
            },
            {
              title: 'Webhooks',
              icon: WebhooksIcon,
              link: '/webhooks/',
            },
          ],
          [
            {
              title: 'Licence',
              icon: LicenseIcon,
            },
          ],
        ],
      },
    });
  }

  return {
    topNavGroups: baseTopNavGroups,
    bottomNavGroups: [bottomNavGroup],
  };
};

export default getNavigationForUser;
