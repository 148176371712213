import React from 'react';
import moment from 'moment';
import { DateType } from '@date-io/type';
import { styled, Theme } from '@mui/material/styles';
import MuiDatePicker from '@mui/lab/DatePicker';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay/PickersDay';
import MuiTextField, {
  TextFieldProps as BaseTextFieldProps,
} from '@mui/material/TextField';
import { useInput } from 'contexts';
import config from 'config';

interface Props {
  name: string;
  margin?: BaseTextFieldProps['margin'];
  required?: BaseTextFieldProps['required'];
  [prop: string]: any;
}

const DatePicker = styled(MuiDatePicker)(({ theme }) => ({
  color: theme.palette.warning.contrastText,
}));

const Day = styled(PickersDay)<PickersDayProps<unknown>>(
  ({ theme, ...ownerState }: PickersDayProps<unknown> & { theme: Theme }) => ({
    width: theme.spacing(4.5),
    height: theme.spacing(4.4),
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(0, 0.25),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    background: 'transparent',
    transform: 'scale(1.1)',
    '& > *': {
      transform: 'scale(0.9)',
    },
    ...(ownerState.today && {
      color: theme.palette.primary.main,
    }),
  }),
);

interface TextFieldProps extends Omit<BaseTextFieldProps, 'color'> {
  inputError: boolean;
  inputSuccess: boolean;
}

const TextField = styled(MuiTextField, {
  shouldForwardProp: (propName: string) =>
    ['inputError', 'inputSuccess'].indexOf(propName) === -1,
})<TextFieldProps>(
  ({ theme, ...ownerState }: TextFieldProps & { theme: Theme }) => ({
    '.MuiInput-root': {
      ...(ownerState.inputError && {
        '&:before': {
          borderBottomColor: theme.palette.error.main,
        },
      }),
      ...(ownerState.inputSuccess && {
        '&:before': {
          borderBottomColor: theme.palette.success.main,
        },
      }),
      '&.Mui-focused': {
        color: `${theme.palette.warning.contrastText} !important`,
        '&:after': {
          borderBottomColor: `${theme.palette.warning.contrastText} !important`,
        },
      },
    },
    '.MuiFormHelperText-root': {
      ...(ownerState.inputError && {
        color: theme.palette.error.main,
      }),
      ...(ownerState.inputSuccess && {
        color: theme.palette.success.main,
      }),
    },
    '.MuiInputLabel-root': {
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
      ...(ownerState.inputError && {
        color: theme.palette.error.main,
      }),
      ...(ownerState.inputSuccess && {
        color: theme.palette.success.main,
      }),
      '&.Mui-focused': {
        color: `${theme.palette.warning.contrastText} !important`,
        '&:after': {
          borderBottomColor: `${theme.palette.warning.contrastText} !important`,
        },
      },
    },
    '.MuiButtonBase-root': {
      marginRight: 0,
    },
  }),
);

interface WeekenDayProps {
  weekday: boolean;
}

const WeekenDay = styled('div')<WeekenDayProps>(
  ({ theme, ...ownerState }: WeekenDayProps & { theme: Theme }) => ({
    ...(ownerState.weekday && {
      backgroundColor: theme.palette.text.disabled,
      borderRadius: 0,
    }),
  }),
);

const DateInput = ({
  name,
  rules,
  label,
  required,
  enabled,
  margin = 'normal',
  format = config.dateFormat,
  defaultValue = null,
  ...props
}: Props) => {
  const { field, meta, error } = useInput({ name, rules, defaultValue });
  const { isTouched, invalid } = meta;
  const { value, ref, ...restProps } = field;

  const onChange = React.useCallback(
    (date: unknown) => {
      const day = date as moment.Moment | null;

      field.onChange(day ? day.startOf('day').toISOString() : day);
    },
    [field],
  );

  const renderWrappedWeekDay = (
    date: unknown,
    selectedDates: Array<unknown>,
    pickersDayProps: PickersDayProps<unknown>,
  ) => {
    const day = date as DateType;

    return (
      <WeekenDay weekday={day.weekday() >= 5}>
        <Day
          {...pickersDayProps}
          hidden={false}
          disabled={pickersDayProps.outsideCurrentMonth}
        />
      </WeekenDay>
    );
  };

  return (
    <DatePicker
      minDate={config.minDate}
      maxDate={config.maxDate}
      label={label}
      inputFormat={format}
      renderDay={renderWrappedWeekDay}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          helperText={error}
          variant="standard"
          required={required}
          margin={margin}
          inputError={invalid || error}
          inputSuccess={isTouched && !invalid}
          inputProps={{
            ...params.inputProps,
            placeholder: config.dateFormat,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      inputRef={field.ref}
      {...restProps}
      {...props}
      value={value || null}
      onChange={onChange}
    />
  );
};

export default DateInput;
