import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getService, ServiceFn } from 'services';
import HttpError from 'services/HttpError';
import { authSelectors } from 'store/selectors';

interface ActionWithSideEffect {
  type: string;
  payload?: any;
  meta: {
    partial?: boolean;
    service: string;
    onSuccess?: any;
    onFailure?: any;
  };
}

/**
 * Fetch Side Effects
 */
export function* handleFetch({ type, payload, meta }: ActionWithSideEffect) {
  const {
    partial,
    service: serviceName,
    onSuccess: successSideEffects,
    onFailure: failureSideEffects,
    ...restMeta
  } = meta;
  const [mainType] = type.split('/');

  try {
    yield put({ type: 'FETCH_START' });
    const service: ServiceFn = yield call(getService, serviceName, type);
    const accessToken: string = yield select(authSelectors.accessToken);
    const {
      response: { data },
    } = yield call(service, payload, accessToken, partial);
    yield put({
      type: `FETCH_SUCCESS`,
      payload: data,
      requestPayload: payload,
    });
    yield put({
      type: `${mainType}/SUCCESS`,
      payload: data,
      meta: {
        ...restMeta,
        ...successSideEffects,
      },
    });
  } catch (error) {
    yield put({
      type: `FETCH_FAILURE`,
      error:
        error && (error as HttpError).message
          ? (error as HttpError).message
          : null,
      payload: (error && (error as HttpError).body) || null,
      requestPayload: payload,
    });
    yield put({
      type: `${mainType}/FAILURE`,
      meta: {
        ...restMeta,
        ...failureSideEffects,
      },
    });
  }
}

function* serviceSideEffect() {
  yield takeEvery(
    (action: any) => action.meta && action.meta.service,
    handleFetch,
  );
}

export default serviceSideEffect;
