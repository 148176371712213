import Immutable from 'immutable';
import { createResourceReducer } from './utils';
import { searchActions } from 'store/actions';
import { searchFilters } from 'store/configs';
import {
  FilterConf,
  OperationalData,
  ResourceRecord,
  ResourceReducer,
} from 'types';
import { ApplyFiltersConfPayload } from 'store/actions/search';
import resources from 'store/resources';

export const InitialSearchState = Immutable.Record<
  ResourceReducer<OperationalData>
>({
  filtersConf: searchFilters,
  filters: {},
  sort: [],
  isLoading: false,
  count: 0,
  pagination: {
    perPage: 10,
    page: 1,
  },
  currentItem: null,
  autocomplete: [],
  results: [],
  errors: {},
});

export type SearchState = ResourceRecord<OperationalData>;

const applyFiltersConf = (
  state: SearchState,
  payload: ApplyFiltersConfPayload,
) => {
  return state.set(
    'filtersConf',
    Object.keys(payload).reduce(
      (newFilterConf: FilterConf, filterName: string) => {
        const filterConf = newFilterConf.get(filterName)!;
        return new Map(
          newFilterConf.set(filterName, {
            ...filterConf,
            enabled: payload[filterName],
          }),
        );
      },
      state.get('filtersConf'),
    ),
  );
};

const searchReducer = createResourceReducer({
  resource: resources.SEARCH,
  initialState: InitialSearchState(),
  handlers: {
    [searchActions.applyFiltersConf.TRIGGER]: applyFiltersConf,
  },
});

export default searchReducer;
