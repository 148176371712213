import React from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { capitalize } from '@mui/material/utils';
import MuiIconButton from '@mui/material/IconButton';
import config from 'config';
import { OperationalData } from 'types';
import Typography from 'components/Typography';
import Grid from 'components/Grid';
import { ReactComponent as TrashIcon } from 'assets/remove.svg';

const ItemContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
}));

const TextOverflow = styled('div')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
}));

const GridTextOverflow = styled(Grid)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '100%',
}));

const DocumentNameContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  marginBottom: '14px',
}));

const DocTypeGrid = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.info.main,
  padding: 0,
  marginLeft: theme.spacing(2),
}));

const RemoveIcon = styled(TrashIcon)(() => ({
  height: '24px',
  width: 'auto',
}));

export interface BasketPopoverListProps {
  removeItems: (p: { documents: OperationalData[] }) => void;
  documents: OperationalData[];
}

const BasketPopoverList = ({
  documents,
  removeItems,
}: BasketPopoverListProps) => (
  <React.Fragment>
    {documents.map((doc: OperationalData) => (
      <ItemContainer key={doc.id}>
        <div>
          <TextOverflow>
            <GridTextOverflow>
              <DocumentNameContainer>
                <Typography noWrap variant="h6">
                  {doc.document_name ? doc.document_name : 'N/A'}
                </Typography>
              </DocumentNameContainer>
            </GridTextOverflow>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Grid container direction="row" item xs={6}>
                <DocTypeGrid>
                  <Typography variant="body2">Document Type:&nbsp;</Typography>
                  <Typography noWrap variant="body2" weight="medium">
                    {(doc.doc_types || [])
                      .map((documentType) => capitalize(documentType.doc_type))
                      .join(', ')}
                  </Typography>
                </DocTypeGrid>
              </Grid>
              <Grid container item xs justifyContent="flex-end">
                <Typography variant="body2">Publication date:&nbsp;</Typography>

                <Typography variant="body2" weight="bold">
                  {doc.publication_date
                    ? moment(doc.publication_date).format(config.dateFormat)
                    : 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </TextOverflow>
        </div>
        <div>
          <IconButton
            title="notifications"
            onClick={() => {
              removeItems({ documents: [doc] });
            }}
            size="large"
          >
            <RemoveIcon />
          </IconButton>
        </div>
      </ItemContainer>
    ))}
  </React.Fragment>
);

export default BasketPopoverList;
