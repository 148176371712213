import { Empty, PageResponse, request, ResponseData } from 'services/request';
import { OperationalData, RelatedDocuments } from 'types';

interface DetailPayload {
  id: number;
}

const list = (query: DetailPayload, token: string) =>
  request<Empty, RelatedDocuments, Empty>(
    `wm-objects/related-document/${query.id}`,
    {
      method: 'GET',
    },
    token,
  ).then(
    ({ response, error }) =>
      ({
        error,
        response: {
          ...response,
          data: {
            results: response?.data?.related_op_data_docs,
            count: response?.data?.related_op_data_docs.length,
          },
        },
      } as ResponseData<PageResponse<OperationalData>>),
  );

interface Payload extends DetailPayload {
  document_name: string;
  related_op_data_docs: number[];
}

const update = (payload: Payload, token: string) => {
  return request<Empty, PageResponse<RelatedDocuments>, Empty>(
    `wm-objects/related-document/${payload.id}`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );
};

const relatedDocumentsServices = {
  list,
  update,
};

export default relatedDocumentsServices;
