import { createRoutine } from './utils';

/**
 * @deprecated It's deprecated. Use useDrawer hook instead.
 */
export const showDrawer = createRoutine('SHOW_DRAWER');
/**
 * @deprecated It's deprecated. Use useDrawer hook instead.
 */
export const hideDrawer = createRoutine('HIDE_DRAWER');

const drawerActions = {
  showDrawer,
  hideDrawer,
};

export default drawerActions;
