import React from 'react';
import { ControllerFieldState as InputState } from 'react-hook-form';
import MuiFormHelperText, {
  FormHelperTextProps as MuiFormHelperTextProps,
} from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

import { StyledComponent } from '@mui/styles';
export interface ErrorTextProps extends MuiFormHelperTextProps {
  invalid?: boolean;
}

export interface HelperTextProps {
  ml?: number;
}

const ErrorText = styled(MuiFormHelperText)<ErrorTextProps>(
  ({ theme, invalid }) => ({
    color: invalid ? theme.palette.error.main : theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  }),
) as StyledComponent<ErrorTextProps>;

const HelperText = styled(MuiFormHelperText)<{ ml?: number }>(
  ({ theme, ml }) => ({
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(ml || 2),
    fontSize: theme.typography.body2.fontSize,
  }),
);

export interface FieldHelperProps {
  meta: InputState;
  error?: string;
  helpText?: React.ReactNode;
  HelperTextProps?: HelperTextProps;
}

const FieldHelper = ({
  helpText,
  error,
  meta: { invalid },
  HelperTextProps,
}: FieldHelperProps) => {
  const showError = Boolean(invalid || error);
  return (
    <React.Fragment>
      {Boolean(showError) && (
        <ErrorText invalid={showError}>
          {error ? error : 'This field is required'}
        </ErrorText>
      )}
      {Boolean(!showError) && helpText && (
        <HelperText {...HelperTextProps}>{helpText}</HelperText>
      )}
    </React.Fragment>
  );
};

export default FieldHelper;
