import React from 'react';
import get from 'lodash/get';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { JWTToken } from 'types';
import { authSelectors } from 'store/selectors';
import { ReducerState } from 'store/reducers';
import { AppLayout } from 'layouts';
import TitleRoute, { TitleRouteProps } from 'routingComponents/TitleRoute';

interface ConnectStateProps {
  isAuthenticated: boolean;
  jwtToken: JWTToken;
  email: string;
}

type AuthPermission =
  | 'is_org_admin'
  | 'is_staff'
  | 'is_group_admin'
  | 'is_workflow_admin'
  | 'is_superuser'
  | 'are_workflows_allowed';

type FeatureFlag = 'workflow';

interface PrivateRouteProps extends TitleRouteProps, ConnectStateProps {
  permissions?: AuthPermission | AuthPermission[];
  featureFlags?: FeatureFlag | FeatureFlag[];
  test?: (email: string, jwtToken: JWTToken) => boolean;
}

const PrivateRoute = React.memo(
  ({
    children,
    jwtToken,
    permissions = [],
    featureFlags = [],
    isAuthenticated,
    email,
    test,
    location,
    ...rest
  }: PrivateRouteProps) => {
    const hasAccess = React.useMemo(() => {
      const authPermissions = Array.isArray(permissions)
        ? permissions
        : [permissions];
      const accessFeatureFlags = Array.isArray(featureFlags)
        ? featureFlags
        : [featureFlags];
      if (isAuthenticated) {
        const userEnabledFeatures = Object.fromEntries(
          jwtToken.payload.permissions.map((featureFlag) => [
            featureFlag,
            true,
          ]),
        );
        if (test) {
          return test(email, jwtToken);
        }
        const hasPermissionAccess =
          authPermissions.length !== 0
            ? authPermissions.findIndex(
                (permission) =>
                  get(jwtToken.payload, permission, false) === true,
              ) !== -1
            : true;
        const hasFeatureAccess =
          accessFeatureFlags.length !== 0
            ? accessFeatureFlags.findIndex(
                (featureFlag) => userEnabledFeatures[featureFlag] === true,
              ) !== -1
            : true;
        return accessFeatureFlags.length !== 0 && authPermissions.length !== 0
          ? hasFeatureAccess || hasPermissionAccess
          : hasFeatureAccess && hasPermissionAccess;
      }
      return false;
    }, [featureFlags, permissions, isAuthenticated, test, email, jwtToken]);

    const path = location?.search
      ? encodeURIComponent(`${location?.pathname}${location?.search}`)
      : location?.pathname;
    return hasAccess ? (
      <TitleRoute {...rest}>
        <AppLayout>{children}</AppLayout>
      </TitleRoute>
    ) : (
      <Redirect
        to={{
          pathname: isAuthenticated ? '/dashboard' : '/sign-in',
          search: isAuthenticated ? undefined : `next=${path}`,
        }}
      />
    );
  },
);

export default connect(
  (state: ReducerState) =>
    ({
      email: authSelectors.email(state),
      isAuthenticated: authSelectors.isAuthenticated(state),
      jwtToken: authSelectors.jwtToken(state),
    } as ConnectStateProps),
)(PrivateRoute);
