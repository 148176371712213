import React from 'react';

import WaymarkLogo from 'assets/waymark_logo.png';

const LogoLoader = () => (
  <div className="full-page-loader">
    <img width="192" src={WaymarkLogo} alt="Waymark Tech logo" />
  </div>
);

export default LogoLoader;
