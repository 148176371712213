import React from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import MuiFormLabel from '@mui/material/FormLabel';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { useInput } from 'contexts';

import Grid from 'components/Grid';
import Typography from 'components/Typography';
import FieldHelper from '../FormInputControl/FieldHelper';
import { NumberFormatInput } from '../NumberInput';

const Input = styled(MuiInput)(({ theme }) => ({
  '& .Mui-input': {
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
  },
  '& .Mui-focused': {
    color: `${theme.palette.warning.contrastText} !important`,
    '&:after': {
      borderBottomColor: `${theme.palette.warning.contrastText} !important`,
    },
  },
}));

const FormLabel = styled(MuiFormLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  transform: `translate(0, 1.5px) scale(0.75)`,
  transformOrigin: `top left`,
}));

export interface RangeInputProps {
  helpText?: string;
  name: string;
  [prop: string]: any;
}

interface InternalValueState {
  gte: string;
  lte: string;
}

const RangeInput = ({
  classes,
  label,
  name,
  helpText,
  ...props
}: RangeInputProps) => {
  const { field, meta, error } = useInput({ name });
  const focusRef = React.useRef<boolean>(false);
  const [internalValue, setInternalValue] = React.useState<InternalValueState>({
    gte: get(field.value, 'gte', ''),
    lte: get(field.value, 'lte', ''),
  });
  // callbacks
  const onFocus = React.useCallback(() => {
    focusRef.current = true;
  }, [focusRef]);
  const onBlur = React.useCallback(() => {
    focusRef.current = false;
  }, [focusRef]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDebounce = React.useCallback(
    debounce((inputValue) => {
      field.onChange(inputValue);
    }, 5e2),
    [],
  );
  const onChange = React.useCallback(
    (value: string, inputType: 'gte' | 'lte') => {
      setInternalValue({
        ...internalValue,
        [inputType]: value,
      });
    },
    [setInternalValue, internalValue],
  );
  const onStartChange = React.useCallback(
    (evt) => {
      onChange(evt.target.value, 'gte');
    },
    [onChange],
  );
  const onEndChange = React.useCallback(
    (evt) => {
      onChange(evt.target.value, 'lte');
    },
    [onChange],
  );

  // effects
  React.useEffect(() => {
    const inputValue = {} as Record<string, number>;
    const gte = get(field.value, 'gte', '').toString();
    const lte = get(field.value, 'lte', '').toString();
    if (
      !focusRef.current &&
      (internalValue.lte.toString() !== lte ||
        internalValue.gte.toString() !== gte)
    ) {
      setInternalValue({ gte, lte });
      return;
    }

    if (
      internalValue.gte !== '' &&
      !isNaN(internalValue.gte as any) &&
      gte !== internalValue.gte.toString()
    ) {
      inputValue.gte = parseFloat(internalValue.gte);
    }
    if (
      internalValue.lte !== '' &&
      !isNaN(internalValue.lte as any) &&
      lte !== internalValue.lte.toString()
    ) {
      inputValue.lte = parseFloat(internalValue.lte);
    }
    if (!inputValue.gte && !inputValue.lte) return;
    onChangeDebounce({
      gte,
      lte,
      ...inputValue,
    });
  }, [field, internalValue, setInternalValue, onChangeDebounce]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid container>
      <Grid item>
        {/* @ts-ignore */}
        <FormLabel htmlFor={name} component="legend">
          {label}
        </FormLabel>
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <Input
            id={name}
            autoComplete="false"
            error={meta.invalid}
            inputComponent={NumberFormatInput as any}
            value={internalValue.gte}
            onChange={onStartChange}
            onFocus={onFocus}
            onBlur={onBlur}
            {...props}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={2}>
          <Typography variant="body2">____</Typography>
        </Grid>
        <Grid item xs={5}>
          <Input
            autoComplete="false"
            error={Boolean(meta.invalid)}
            inputComponent={NumberFormatInput as any}
            value={internalValue.lte}
            onChange={onEndChange}
            onFocus={onFocus}
            onBlur={onBlur}
            {...props}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FieldHelper error={error} meta={meta} helpText={helpText} />
      </Grid>
    </Grid>
  );
};

export default RangeInput;
