import { styled } from '@mui/material/styles';
import { PopoverItem } from './PopoverContext';
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
  PopoverOrigin,
} from '@mui/material/Popover';

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export interface PopoverProps extends MuiPopoverProps {
  width?: string;
  pointerEvents?: boolean;
}

const Popover = styled(MuiPopover, {
  shouldForwardProp: (propName: string) =>
    ['width', 'pointerEvents'].indexOf(propName) === -1,
})<PopoverProps>(({ width, pointerEvents }) => ({
  ...(pointerEvents && { pointerEvents: 'none' }),
  '&. Paper-root': {
    maxWidth: '50vw',
    ...(width && { width: width }),
  },
}));

export interface PopoverDisplayProps {
  popoverItem: PopoverItem;
  close: (popover: PopoverItem) => any;
}

export const PopoverDisplay = ({ popoverItem, close }: PopoverDisplayProps) => {
  return (
    <Popover
      pointerEvents={popoverItem.pointerEvents}
      disableRestoreFocus={popoverItem.disableRestoreFocus}
      id={popoverItem.name}
      width={popoverItem.width}
      open={Boolean(popoverItem.ref.current)}
      anchorEl={popoverItem.ref.current}
      onClose={close(popoverItem)}
      anchorOrigin={popoverItem.anchorOrigin || DEFAULT_ANCHOR_ORIGIN}
      transformOrigin={popoverItem.transformOrigin || DEFAULT_TRANSFORM_ORIGIN}
    >
      {popoverItem.content}
    </Popover>
  );
};

export default PopoverDisplay;
