import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface UseInput {
  field: ControllerRenderProps;
  useOnEnterChange?: boolean;
  many?: boolean;
  trim?: boolean;
}

const useInput = ({ field, useOnEnterChange, many, trim }: UseInput) => {
  const [inputValue, setInputValue] = React.useState('');
  const onChange = React.useCallback(
    (evt) => {
      if (!useOnEnterChange) {
        field.onChange(evt);
        return;
      }
      setInputValue(evt.target.value);
    },
    [setInputValue, field, useOnEnterChange],
  );
  const onInputChange = React.useCallback(
    (evt) => {
      const value = evt.target ? evt.target.value : evt;
      if (many) {
        const currentValue = field.value || [];
        field.onChange([...currentValue, value]);
        setInputValue('');
        return;
      }
      field.onChange(value);
    },
    [field, many, setInputValue],
  );
  const onKeyDown = React.useCallback(
    (evt) => {
      if (evt.key === 'Enter' && inputValue.trim() !== '') {
        onInputChange({
          target: {
            value: inputValue,
          },
        });
        evt.preventDefault();
      }
    },
    [inputValue, onInputChange],
  );

  const value = React.useMemo(() => {
    return useOnEnterChange ? inputValue : field.value;
  }, [field, inputValue, useOnEnterChange]);

  const onBlur = React.useCallback(() => {
    if (trim && field.value && typeof field.value === 'string') {
      field.onChange(field.value.trim());
    }
    field.onBlur();
  }, [trim, field]);

  return {
    onChange,
    onBlur,
    onKeyDown,
    value,
  };
};

export default useInput;
