import React from 'react';
import isFunction from 'lodash/isFunction';
import {
  ControllerRenderProps,
  ControllerFieldState as InputState,
} from 'react-hook-form';
import { FormControlProps } from '@mui/material/FormControl';
import { styled, Theme } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { useConfirm } from 'hooks';
import { ReactComponent as MenuIcon } from 'assets/menu.svg';
import checkIcon from 'assets/check.svg';

import useMany from '../useMany';
import useChoices, { OptionText, OptionTextElement } from '../useChoices';
import FormInputControl from '../FormInputControl';
import Input from './Input';

export interface SelectProps extends MuiSelectProps {
  valid?: boolean;
}

const Select = styled(MuiSelect)<SelectProps>(
  ({ theme, valid }: SelectProps & { theme: Theme }) => ({
    padding: 0,
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:after': {
      borderBottomColor: theme.palette.warning.contrastText,
    },
    '& .MuiSelect-icon': {
      padding: theme.spacing(1),
      width: 24,
      height: 24,
    },
    ...(valid !== undefined && {
      '&:before': {
        borderBottomColor: valid
          ? theme.palette.success.main
          : theme.palette.error.main,
      },
    }),
    '& .MuiMenu-paper': {
      boxShadow: '0 2px 4px #00000029',
    },
    '& .MuiMenu-list': {
      color: theme.palette.warning.contrastText,
      '&:focus': {
        backgroundColor: theme.palette.text.disabled,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.warning.contrastText,
        },
      },
    },
  }),
);

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  '& .Mui-selected': {
    '&:after': {
      content: `url(${checkIcon})`,
      position: 'absolute',
      right: 10,
      top: 8,
    },
  },
}));

export interface SelectInputProps {
  [prop: string]: any;
  name: string;
  field: ControllerRenderProps;
  isLoading?: boolean;
  meta?: InputState;
  required?: SelectProps['required'];
  margin?: FormControlProps['margin'];
  many?: boolean;
  helpText?: string;
  optionText?: OptionTextElement | OptionText | string;
  optionValue?: string;
  choices?: Record<string, any>[];
  onChange?: (value: number | string, optionValue: string) => void;
  shouldConfirm?: boolean | ((value: number | string) => boolean);
  confirmMessage?: string;
  clearable?: boolean;
}

const sanitizeRestProps = ({
  pagination,
  setPagination,
  perPage,
  page,
  setPage,
  setPerPage,
  sort,
  filters,
  setFilterValue,
  autocomplete,
  count,
  ...rest
}: any) => rest;

const SelectInput = ({
  name,
  helpText,
  label,
  classes,
  many = false,
  children,
  meta = {} as InputState,
  field,
  required,
  error,
  choices = [],
  isLoading,
  margin = 'normal',
  optionText = 'name',
  optionValue = 'id',
  defaultValue = '',
  confirmMessage = 'Confirm your selection',
  onChange: onChangeOverride,
  shouldConfirm,
  clearable = false,
  ...props
}: SelectInputProps) => {
  const { isTouched, invalid } = meta;
  const confirm = useConfirm({ title: confirmMessage });
  const onChangeWrapper = React.useCallback(
    (value) => {
      if (
        isFunction(shouldConfirm)
          ? shouldConfirm(value)
          : Boolean(shouldConfirm)
      ) {
        confirm(() => {
          field.onChange(value);
        });
        return;
      }
      field.onChange(value);
      if (onChangeOverride) {
        onChangeOverride(value, optionValue);
      }
    },
    [shouldConfirm, field, onChangeOverride, confirm, optionValue],
  );
  const { onChange, value } = useMany({
    field: { ...field, onChange: onChangeWrapper },
    many,
    getValue: (evt: React.ChangeEvent<HTMLSelectElement>) => evt.target.value,
    defaultValue: defaultValue,
  });

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
  });

  const renderMenuItemOption = React.useCallback(
    (choice: Record<string, any>) => getChoiceText(choice),
    [getChoiceText],
  );

  const clear = React.useCallback(() => {
    field.onChange('');
  }, [field]);

  return (
    <FormInputControl
      label={label}
      meta={meta}
      required={required}
      name={field.name}
      variant="standard"
      loading={isLoading}
      error={error}
      helpText={helpText}
    >
      <Select
        id={name}
        required={required}
        valid={isTouched ? !Boolean(error || invalid) : undefined}
        IconComponent={MenuIcon}
        MenuProps={{
          elevation: 2,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
        input={<Input clearable={clearable} clear={clear} />}
        {...field}
        value={choices && choices.length ? value || '' : ''}
        onChange={onChange}
        {...sanitizeRestProps(props)}
      >
        {choices.map((choice: Record<string, any>) => (
          <MenuItem key={getChoiceValue(choice)} value={getChoiceValue(choice)}>
            {renderMenuItemOption(choice)}
          </MenuItem>
        ))}
      </Select>
    </FormInputControl>
  );
};

export default SelectInput;
