import Grid from 'components/Grid';
import { styled } from '@mui/material/styles';
import BtnContainer from 'layouts/AppLayout/BtnContainer';
import AccessibilityMode from 'layouts/AppLayout/AccessibilityMode';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Badge from 'components/Badge';
import { ReactComponent as BasketIcon } from 'assets/basket.svg';
import { ReactComponent as BellIcon } from 'assets/bell.svg';
import UserMenu from 'components/UserMenu';
import React from 'react';
import { JWTToken } from 'types';

const MenuBlock = styled(Grid)(() => ({
  flex: 2,
  minWidth: 0,
}));

const UserMenuBlock = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  minWidth: 0,
}));

export interface MenuProps {
  jwtToken: JWTToken;
  onBasketClick: IconButtonProps['onClick'];
  documentsCount?: number;
  onNotificationClick: () => void;
  notificationsCount: number;
  firstName: string | null;
  lastName: string | null;
}

const Menu = ({
  jwtToken,
  onBasketClick,
  onNotificationClick,
  documentsCount,
  notificationsCount,
  firstName,
  lastName,
}: MenuProps) => (
  <Grid container wrap="nowrap" alignItems="center" mx={2}>
    <MenuBlock item container alignItems="center" justifyContent="flex-end">
      <BtnContainer item>
        <AccessibilityMode />
      </BtnContainer>
      {!jwtToken.payload.file_uploads_access_only && (
        <BtnContainer item>
          <IconButton
            title="Basket"
            onClick={onBasketClick}
            aria-label="basket"
            size="large"
          >
            <Badge badgeContent={documentsCount || 0} color="error">
              <BasketIcon />
            </Badge>
          </IconButton>
        </BtnContainer>
      )}
      <BtnContainer item>
        <IconButton
          title="notifications"
          onClick={onNotificationClick}
          size="large"
        >
          <Badge badgeContent={notificationsCount} color="error">
            <BellIcon />
          </Badge>
        </IconButton>
      </BtnContainer>
    </MenuBlock>
    <UserMenuBlock item actualSize>
      <UserMenu firstName={firstName} lastName={lastName} />
    </UserMenuBlock>
  </Grid>
);

export default Menu;
