import React from 'react';
import moment from 'moment';
import { Theme, styled } from '@mui/material/styles';
import Day from '@mui/lab/PickersDay';
import DateRangeWeekday from './DateRangeWeekday';
import DateRangeSelectDays from './DateRangeSelectDays';
import DateRangePickerDays from './DateRangePickerDays';

export const endBorderStyle = {
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
};

export const startBorderStyle = {
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
};

interface DateRangeDayProps {
  isHighlighting: boolean;
  isEndOfHighlighting: boolean;
  isStartOfHighlighting: boolean;
  isPreviewing: boolean;
  isSelected: boolean;
  isEndOfPreviewing: boolean;
  isStartOfPreviewing: boolean;
  day: moment.Moment;
  className?: string;
  onMouseEnter: (evt: React.MouseEvent<HTMLDivElement>) => any;
  onDaySelect: (day: unknown) => void;
  outsideCurrentMonth: boolean;
  onMouseLeave: () => void;
}

const StyledDay = styled(Day, {
  shouldForwardProp: (propName: string) => {
    return ['isHighlighting', 'isSelected'].indexOf(propName) === -1;
  },
})<Partial<DateRangeDayProps>>(
  ({
    theme,
    ...ownerState
  }: Partial<DateRangeDayProps> & { theme: Theme }) => ({
    width: theme.spacing(4.5),
    height: theme.spacing(4.4),
    fontSize: theme.typography.caption.fontSize,
    margin: theme.spacing(0, 0.25),
    color: theme.palette.text.primary,
    background: 'transparent',
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
    transform: 'scale(1.1)',
    '& > *': {
      transform: 'scale(0.9)',
    },
    '&:hover, &:focus': {
      border: '1px solid #9e9e9e',
    },
    ...(ownerState.isHighlighting && {
      color: theme.palette.primary.contrastText,
    }),
    ...(ownerState.isSelected && {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      // @ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  }),
);

const DateRangeDay = ({
  className,
  day,
  isEndOfHighlighting,
  isEndOfPreviewing,
  isHighlighting,
  isPreviewing,
  isStartOfHighlighting,
  isStartOfPreviewing,
  isSelected,
  onMouseLeave,
  onMouseEnter,
  onDaySelect,
  outsideCurrentMonth,
}: DateRangeDayProps) => {
  const now = moment();
  const isStartOfWeek = moment(day).startOf('isoWeek').isSame(day, 'day');
  const isEndOfWeek = moment(day).endOf('isoWeek').isSame(day, 'day');

  return (
    <DateRangeWeekday weekendDay={day.weekday() >= 5}>
      <DateRangeSelectDays
        onMouseEnter={!outsideCurrentMonth ? onMouseEnter : undefined}
        onMouseLeave={onMouseLeave}
        isHighlighting={isHighlighting}
        isEndOfHighlighting={isEndOfHighlighting}
        isStartOfHighlighting={isStartOfHighlighting}
        isStartOfWeek={isStartOfWeek}
        isEndOfWeek={isEndOfWeek}
      >
        <DateRangePickerDays
          isPreviewing={isPreviewing}
          isEndOfPreviewing={isEndOfPreviewing}
          isStartOfPreviewing={isStartOfPreviewing}
          isStartOfWeek={isStartOfWeek}
          isEndOfWeek={isEndOfWeek}
        >
          <StyledDay
            outsideCurrentMonth={outsideCurrentMonth}
            today={day === now}
            day={day}
            selected={isSelected}
            isHighlighting={isHighlighting}
            isSelected={isSelected}
            className={className}
            onDaySelect={onDaySelect}
          >
            {!outsideCurrentMonth ? day.date() : ''}
          </StyledDay>
        </DateRangePickerDays>
      </DateRangeSelectDays>
    </DateRangeWeekday>
  );
};

export default DateRangeDay;
