import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Folder, OperationalData } from 'types';

const list = (query: PageQuery, token: string) =>
  request<PageQuery, PageResponse<Folder>, Empty>(
    'folder/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = (payload: Folder, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/`,
    {
      payload,
      method: 'POST',
    },
    token,
  );

interface DetailPayload {
  id: number | string;
}

const detail = ({ id }: DetailPayload, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/${id}`,
    {
      method: 'GET',
    },
    token,
  );

const update = (payload: Folder, token: string) =>
  request<Folder, Folder, Empty>(
    `folder/${payload.id}/`,
    {
      payload,
      method: 'PATCH',
    },
    token,
  );

const remove = ({ id }: DetailPayload, token: string) =>
  request<Folder, Empty, Empty>(
    `folder/${id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

export interface AppendDocumentsPayload {
  id: number;
  documents: OperationalData[];
}

const appendDocuments = (
  { id, documents }: AppendDocumentsPayload,
  token: string,
) =>
  request<Partial<Folder>, Empty, Empty>(
    `folder/${id}/documents/`,
    {
      payload: { documents },
      method: 'PUT',
    },
    token,
  );

interface RemoveSubresourcePayload {
  itemId: number;
  resource: string;
  subItemId: number;
}

const removeSubresource = (
  { itemId, resource, subItemId }: RemoveSubresourcePayload,
  token: string,
) => {
  return request<Folder, Empty, Empty>(
    `folder/${itemId}/documents/${subItemId}/`,
    {
      method: 'DELETE',
    },
    token,
  );
};

interface AppendSubresourcesPayload {
  id: number;
  subresource: string;
  items: number[];
}

export interface FolderSubresources extends Omit<Folder, 'documents'> {
  documents: number[];
}

const appendSubresources = (
  { id, subresource, items }: AppendSubresourcesPayload,
  token: string,
) => {
  return request<Partial<FolderSubresources>, Empty, Empty>(
    `folder/${id}/documents/`,
    {
      payload: { documents: items },
      method: 'PUT',
    },
    token,
  );
};

const foldersServices = {
  list,
  create,
  detail,
  update,
  remove,
  appendDocuments,
  removeSubresource,
  appendSubresources,
};

export default foldersServices;
