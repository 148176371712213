import React from 'react';
import { Link } from 'react-router-dom';
import Grid from 'components/Grid';
import { Button } from 'components/buttons';

interface BasketPopoverBtnsProps {
  handleClose: () => void;
  emptyBasket: () => void;
}

const BasketPopoverBtns = ({
  emptyBasket,
  handleClose,
}: BasketPopoverBtnsProps) => {
  return (
    <Grid item container justifyContent="center">
      <Grid item container mt={2} justifyContent="space-between">
        <Button
          sx={{ maxWidth: 149 }}
          variant="outlined"
          color="info"
          size="small"
          onClick={emptyBasket}
        >
          Empty basket
        </Button>

        <Button
          sx={{ maxWidth: 149 }}
          variant="outlined"
          color="primary"
          type="submit"
          size="small"
          onClick={handleClose}
          component={Link}
          to="/basket"
        >
          Go To Basket
        </Button>
      </Grid>
    </Grid>
  );
};

export default BasketPopoverBtns;
