import { FormContextValue } from 'contexts/Form/types';
import React from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { DevTool } from '@hookform/devtools';
import {
  SubmitHandler,
  FieldValues,
  UseFormProps,
  useForm,
} from 'react-hook-form';
import yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Errors } from 'types';
import config from 'config';
import { FormContextProvider } from './FormContextProvider';
import Form, { FormProps } from './Form';
import parseServerError from './parseServerError';
import getObjectWithValues from 'contexts/Form/getObjectWithValues';

export interface FormProviderProps
  extends Omit<UseFormProps, 'resolver'>,
    Omit<FormProps, 'onSubmit'> {
  onSubmit: SubmitHandler<FieldValues | any>;
  keepSubmitMode?: boolean;
  schema?: yup.AnyObjectSchema;
  errors?: Errors;
}

const FormProvider = ({
  errors: externalErrors,
  direction,
  align,
  spacing,
  isLoading,
  className,
  children,
  schema,
  mode,
  onSubmit,
  keepSubmitMode = true,
  shouldUnregister = true,
  defaultValues = {},
  ...props
}: FormProviderProps) => {
  const prevValues = React.useRef(defaultValues);
  const wasReset = React.useRef(false);
  const {
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
    register,
    clearErrors,
    unregister,
  } = useForm({
    ...props,
    mode,
    reValidateMode: 'onChange',
    shouldUnregister: shouldUnregister,
    defaultValues: isEmpty(defaultValues) ? undefined : defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const errors = React.useMemo(() => formState.errors, [formState]);
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        mode === 'onChange' &&
        keepSubmitMode &&
        !isEqual(
          getObjectWithValues(prevValues.current),
          getObjectWithValues(value),
        )
      ) {
        if (wasReset.current && isEmpty(value)) {
          wasReset.current = false;
          return;
        }
        prevValues.current = value;
        onSubmit(value);
      }
    });
    return () => subscription.unsubscribe();
  }, [reset, watch, mode, onSubmit, keepSubmitMode, prevValues]);

  React.useEffect(() => {
    const clearedCurrentValues = getObjectWithValues(prevValues.current);
    const clearedDefaultValues = getObjectWithValues(defaultValues);
    if (!isEqual(clearedCurrentValues, clearedDefaultValues)) {
      wasReset.current = true;
      prevValues.current = { ...defaultValues };
      reset(
        { ...defaultValues },
        {
          keepErrors: false,
          keepDirty: true,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: true,
        },
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultValues]);

  const formContextValue = {
    setValue,
    getValues,
    watch,
    control,
    trigger,
    formState,
    errors,
    serverErrors: externalErrors,
    register,
    unregister,
    reset,
    clearErrors,
  };
  return (
    <FormContextProvider value={formContextValue as FormContextValue}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        direction={direction}
        align={align}
        error={
          externalErrors
            ? parseServerError('_error', externalErrors)
            : undefined
        }
        spacing={spacing}
        isLoading={isLoading}
        className={className}
        children={children}
      />
      {!config.isProduction() && control && (
        <DevTool placement="top-right" control={control} />
      )}
    </FormContextProvider>
  );
};

export default FormProvider;
