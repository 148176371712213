import React from 'react';

const useAnchor = <T extends HTMLElement>() => {
  const [anchor, setAnchor] = React.useState<T | null>(null);

  const onAnchorClick = React.useCallback(
    (event: React.MouseEvent<T>) => {
      setAnchor(event.currentTarget);
    },
    [setAnchor],
  );

  const removeAnchor = React.useCallback(() => {
    setAnchor(null);
  }, [setAnchor]);

  return {
    onAnchorClick,
    removeAnchor,
    anchor,
  };
};

export default useAnchor;
