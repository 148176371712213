import { FilterConf, FilterType } from 'types';
import resources from 'store/resources';

const usersFilters: FilterConf = new Map([
  [
    'organization_id',
    {
      label: 'Choose your organisation',
      type: FilterType.Autocomplete,
      config: {
        resource: resources.ORGANISATIONS,
        optionValue: 'id',
        optionText: 'name',
      },
      enabled: true,
    },
  ],
]);

export default usersFilters;
