import React from 'react';
import merge from 'lodash/merge';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import get from 'lodash/get';
import { Column } from 'react-table';

import PaginatedTable from 'components/PaginatedTable';
import { withListController, ListComponentProps } from 'hocs';

export type OnSelect = (items: Array<number>) => void;

type Item = Record<string, any>;

interface Props extends ListComponentProps<any> {
  onSelect: OnSelect;
  columns: Array<Column>;
  hasUpdateInPlace: boolean;
  initialData?: Array<Item>;
  idField?: string;
}

const ResourceItemsPanel = ({
  count,
  pagination,
  results,
  isLoading,
  onPageChange,
  onSelect,
  columns,
  updateInPlace,
  initialData,
  hasUpdateInPlace,
  idField = 'id',
}: Props) => {
  const onSelectHandler = React.useCallback(
    (selectedItems: Array<any>) => {
      onSelect(selectedItems);
    },
    [onSelect],
  );
  const data = React.useMemo(() => {
    if (initialData) {
      // merge the data by idField
      return values(
        merge(keyBy(results, idField), keyBy(initialData, idField)),
      );
    }
    return results;
  }, [results, initialData, idField]);
  const initialSelectedIds = initialData?.reduce(
    (prevItems: Record<string | number, boolean>, item: Item) => {
      return {
        ...prevItems,
        [get(item, idField)]: true,
      };
    },
    {} as Record<string | number, boolean>,
  );
  return (
    <PaginatedTable
      selectable
      align="flex-start"
      data={data}
      isLoading={isLoading}
      rowIdField={idField}
      initialSelectedIds={initialSelectedIds}
      updateInPlace={hasUpdateInPlace ? updateInPlace : undefined}
      totalItems={count}
      itemsPerPage={pagination.perPage}
      currentPage={pagination.page}
      onPageChange={onPageChange}
      onSelect={onSelectHandler}
      columns={columns}
    />
  );
};

export interface DynamicResourceItemsPanelProps {
  resource: string;
  onSelect: OnSelect;
  hasUpdateInPlace?: Props['updateInPlace'];
  filters?: Props['filters'];
  columns: Array<Column>;
  initialData?: Props['initialData'];
  idField?: Props['idField'];
}

const DynamicResourceItemsPanel = ({
  resource,
  columns,
  filters,
  onSelect,
  hasUpdateInPlace,
  initialData,
  idField,
}: DynamicResourceItemsPanelProps) => {
  const ControlledPanel = withListController(resource)(ResourceItemsPanel);
  return (
    <ControlledPanel
      isStatic
      idField={idField}
      initialData={initialData}
      hasUpdateInPlace={hasUpdateInPlace}
      baseFilters={filters}
      columns={columns}
      onSelect={onSelect}
    />
  );
};

export default DynamicResourceItemsPanel;
