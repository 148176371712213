import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Errors } from 'types';
import useStep from './useStep';

interface UseWizardProps<T extends object> {
  maxStep: number;
  initial?: Partial<T>;
  isLoading?: boolean;
  submit: (item: Partial<T>) => void;
  errors?: Errors;
}

const useWizard = <T extends object>({
  maxStep,
  initial = {},
  submit,
  isLoading,
  errors,
}: UseWizardProps<T>) => {
  const isReset = React.useRef<boolean>(true);
  const { currentStep, incrementStep, setStep, isLastStep } = useStep(maxStep);
  const [data, setData] = React.useState<Partial<T>>(initial);
  const onSubmit = React.useCallback(
    (newDetails) => {
      setData((prevData) => ({
        ...prevData,
        ...newDetails,
      }));
      incrementStep();
    },
    [setData, incrementStep],
  );
  React.useEffect(() => {
    if (isLastStep && !isLoading) {
      if (isReset.current) {
        isReset.current = false;
        submit(data);
      }

      if (!isEmpty(errors)) {
        isReset.current = true;
        setStep(0);
        return;
      }
    }
  }, [isLoading, setStep, data, isLastStep, isReset, submit, errors]);
  return {
    isLastStep,
    onSubmit,
    currentStep,
    setStep,
    data,
  };
};

export default useWizard;
