import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import Grid from 'components/Grid';
import { Button } from 'components/buttons';
import Typography from 'components/Typography';

const ErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
  console.error(error);
  return (
    <Grid fullHeight container justifyContent="center" alignItems="center">
      <Grid item xs="auto">
        <Typography variant="body1">Something went wrong :-(</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </Grid>
    </Grid>
  );
};

const ReactErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => window.location.reload()}
  >
    {children}
  </ErrorBoundary>
);

export default ReactErrorBoundary;
