import React from 'react';
import isNil from 'lodash/isNil';
import MuiInput, { InputProps as MuiInputProps } from 'components/ui/Input';
import { styled } from '@mui/material/styles';
import { BaseInputProps } from 'types';
import { UseControllerMethods } from 'contexts';
import ValidationAdornment from 'components/inputs/ValidationAdornment';
import { ReactComponent as ChooseIcon } from 'assets/choose.svg';
import useInputField from './useInput';
import FormInputControl, { FormControlProps } from '../FormInputControl';

const StyledInput = styled(MuiInput)(({ theme, onClick }) => ({
  ...(onClick && {
    cursor: 'pointer',
  }),
  '& .Mui-focused': {
    color: `${theme.palette.warning.contrastText} !important`,
    '&:after': {
      borderBottomColor: `${theme.palette.warning.contrastText} !important`,
    },
  },
}));

export interface InputProps
  extends UseControllerMethods,
    BaseInputProps,
    Omit<MuiInputProps, 'classes' | 'error' | 'margin'> {
  endAdornment?: MuiInputProps['endAdornment'];
  margin?: FormControlProps['margin'];
  error?: string;
  many?: boolean;
  trim?: boolean;
  useOnEnterChange?: boolean;
  isLoading?: boolean;
  formControlProps?: FormControlProps;
}

const Input = ({
  label,
  tip,
  many,
  required,
  helpText,
  onClick,
  className,
  useOnEnterChange,
  field,
  meta,
  type,
  error,
  trim,
  isLoading = false,
  margin = 'normal',
  formControlProps = {},
  ...props
}: InputProps) => {
  const { invalid, isTouched } = meta;
  const { onKeyDown, onChange, onBlur, value } = useInputField({
    trim,
    field,
    useOnEnterChange,
    many,
  });
  return (
    <FormInputControl
      tip={tip}
      label={label}
      meta={meta}
      required={required}
      name={field.name}
      // @ts-ignore
      error={error}
      hidden={type === 'hidden'}
      loading={isLoading}
      margin={margin}
      helpText={helpText}
      {...formControlProps}
    >
      <StyledInput
        id={field.name}
        autoComplete="false"
        errorState={isTouched && invalid}
        success={isTouched && !invalid}
        clickedInput={isNil(onClick) === false}
        required={required}
        error={Boolean((isTouched && invalid) || error)}
        endAdornment={
          onClick ? <ChooseIcon /> : <ValidationAdornment {...meta} />
        }
        onClick={onClick}
        type={type}
        {...field}
        {...props}
        value={value || ''}
        onKeyDown={useOnEnterChange ? onKeyDown : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormInputControl>
  );
};

export default Input;
