import Immutable from 'immutable';
import { ResourceRecord, ResourceReducer, TaskInstance } from 'types';
import { tasksFilters } from 'store/configs';
import resources from 'store/resources';
import { createResourceReducer } from './utils';

export type TasksState = ResourceRecord<TaskInstance>;

export const InitialGroupsState = Immutable.Record<
  ResourceReducer<TaskInstance>
>({
  filtersConf: tasksFilters,
  filters: {},
  sort: [],
  isLoading: false,
  count: 0,
  pagination: {
    perPage: 10,
    page: 1,
  },
  currentItem: null,
  autocomplete: [],
  results: [],
  errors: {},
});

export default createResourceReducer({
  resource: resources.TASKS,
  initialState: new InitialGroupsState(),
});
