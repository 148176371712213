import { createRoutine } from './utils';
import services from 'store/services';
import drawerActions from 'store/actions/drawer';

export const shareSavedSearchAction = createRoutine('SHARE_SAVED_SEARCH');

const shareSavedSearch = (payload: Record<string, any>) =>
  shareSavedSearchAction({
    ...payload,
    meta: {
      service: services.SHARE_SEARCH,
      onSuccess: {
        notification: {
          level: 'success',
          message: 'Saved search was shared.',
        },
        callback: drawerActions.hideDrawer,
        extra: {
          isAction: true,
        },
      },
      onFailure: {
        notification: {
          level: 'error',
          message: 'Something wrong happen during share the search',
        },
      },
    },
  });

const savedSearchActions = {
  shareSavedSearch,
};

export default savedSearchActions;
