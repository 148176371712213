import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  AppliedFilters,
  BaseResource,
  Errors,
  FilterConf,
  Pagination,
  Sort,
} from 'types';
import { ReducerState } from 'store/reducers';
import { crudActions, manualActions } from 'store/actions';
import { generateResourceSelectors } from 'store/selectors/utils';
import ListController from './ListController';
import { Query } from './useListParams';

export interface ListComponentProps<T, RP = {}>
  extends RouteComponentProps<RP> {
  count: number;
  filtersConf: FilterConf;
  filters: AppliedFilters;
  pagination: Pagination;
  sort: Array<Sort>;
  results: Array<T>;
  isLoading: boolean;
  update: (id: string) => void;
  create: (data: T) => void;
  onFilterChange: (filters: AppliedFilters) => any;
  onSortChange: (sort: Array<Sort>) => any;
  onPageChange: (pagination: Pagination) => any;
  updateInPlace: (item: T) => void;
  isSubmitting?: boolean;
  errors?: Errors;
}

type IsDoFetch = (values: Query) => boolean;

export interface ListParams {
  isDoFetch?: IsDoFetch;
  perPage?: number;
  onSuccess?: any;
}

const defaultParams = {
  isDoFetch: () => true,
  perPage: 25,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const withListController = <T extends BaseResource>(
  resourceName: string,
  params: ListParams = {},
) => {
  return (Component: React.ComponentType<any>) =>
    connect(
      (state: ReducerState, props) => {
        const resourceSelectors = generateResourceSelectors(resourceName);
        return {
          ...Object.keys(resourceSelectors).reduce(
            (selectors, selectorName) => {
              return {
                ...selectors,
                [selectorName]: resourceSelectors[selectorName](state),
              };
            },
            { ...props },
          ),
        };
      },
      (dispatch) => ({
        fetch: (args: any) =>
          dispatch(
            crudActions.list({ ...args, meta: { resource: resourceName } }),
          ),
        updateInList: (item: T) =>
          dispatch(
            manualActions.updateInList({
              item,
              meta: { resource: resourceName },
            }),
          ),
        create: (payload: T) =>
          dispatch(
            crudActions.create({
              ...payload,
              meta: {
                resource: resourceName,
                onSuccess: {
                  ...params.onSuccess,
                  runListUpdate: true,
                },
                ...(payload.meta || {}),
              },
            }),
          ),
      }),
    )((props: any) => (
      <ListController
        {...defaultParams}
        {...props}
        {...params}
        component={Component}
      />
    ));
};

export default withListController;
