import { Dispatch } from 'redux';
import { show } from 'redux-modal';
import { UnifiedRoutine } from 'redux-saga-routines';
import { modals } from 'contstants';
import basketActions from 'store/actions/basket';

export interface DeleteOptions {
  action: UnifiedRoutine;
  payload: any;
}

export const remove = (options: DeleteOptions) =>
  show(modals.CONFIRM_DIALOG, { confirmOptions: options });

const emptyBasket = (dispatch: Dispatch) => (itemsCount?: number) =>
  show(modals.EMPTY_BASKET_DIALOG, {
    title:
      itemsCount && !isNaN(itemsCount)
        ? `Are you sure you want to delete ${itemsCount} documents? You won't be able to restore them.`
        : undefined,
    confirm: () => dispatch(basketActions.clear()),
  });

const modalActions = {
  remove,
  emptyBasket,
};

export default modalActions;
