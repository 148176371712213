import React from 'react';
import pick from 'lodash/pick';
import { NotificationActionEvent } from 'openfin-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useIntercom } from 'react-use-intercom';

import { JWTToken, Notification, User } from 'types';
import config from 'config';
import { openfin } from 'integrations';

import { useAnchor, useToggle } from 'hooks';
import { formatUser } from 'utils';
import resources from 'store/resources';
import { drawerActions, notificationActions } from 'store/actions';
import { generateResourceSelectors } from 'store/selectors/utils';
import { ReducerState } from 'store/reducers';
import { authSelectors, basketSelectors } from 'store/selectors';
import { AuthState } from 'store/reducers/auth';
import Grid from 'components/Grid';
import Sidebar from 'components/Sidebar';
import NotificationsPanel from 'components/NotificationsPanel';
import ErrorBoundary from 'components/ErrorBoundary';
import Toolbar from 'components/Toolbar';
import AppBar from 'components/AppBar';
import getNavigationForUser from './getNavigationForUser';

import BasketPopover from './BasketPopover';
import DrawerGrid from './DrawerGrid';
import MainContainer from 'layouts/AppLayout/MainContainer';
import MenuBtn from 'layouts/AppLayout/MenuBtn';
import Menu from 'layouts/AppLayout/Menu';
import Logo from 'layouts/AppLayout/Logo';

const drawerWidth = 260;
const drawerClosedWidth = 60;

export interface AppLayoutProps {
  children: React.ReactNode;
  firstName: AuthState['first_name'];
  lastName: AuthState['last_name'];
  email: AuthState['email'];
  jwtToken: JWTToken;
  notificationsCount: number;
  documentsCount: number;
  markNotification: (item: Notification) => void;
  showNotifications: () => void;
}

const AppLayout = React.memo(
  ({
    children,
    email,
    firstName,
    lastName,
    notificationsCount,
    showNotifications,
    markNotification,
    documentsCount,
    jwtToken,
  }: AppLayoutProps) => {
    const { isMarked: isCollapsed, toggle } = useToggle(false);
    const { boot } = useIntercom();
    const injected = React.useRef(false);

    React.useEffect(() => {
      if (
        config.intercomAppId &&
        jwtToken &&
        jwtToken.payload &&
        !injected.current &&
        email
      ) {
        injected.current = true;
        boot({
          customAttributes: pick(jwtToken.payload, [
            'is_org_admin',
            'is_group_admin',
            'is_workflow_admin',
            'is_superuser',
          ]),
          userId: `${jwtToken.payload.user_id}-${email}`,
          name: formatUser({
            first_name: firstName,
            last_name: lastName,
            email,
          } as User),
          hideDefaultLauncher: true,
          customLauncherSelector: '#intercom',
        });
      }
    }, [boot, jwtToken, email, firstName, lastName, injected]);

    React.useEffect(() => {
      if (config.isOpenFinApp) {
        openfin.subscribe((event: NotificationActionEvent) => {
          markNotification(event.notification.customData as Notification);
        });
      }
    }, [markNotification]);

    const { anchor, onAnchorClick, removeAnchor } =
      useAnchor<HTMLButtonElement>();

    return (
      <React.Fragment>
        <AppBar position="fixed">
          <Toolbar disableGutters>
            <DrawerGrid
              actualSize
              container
              opened={!isCollapsed}
              alignItems="center"
              justifyContent="space-between"
              openWidth={drawerWidth}
              closedWidth={drawerClosedWidth}
            >
              <Grid item>
                <Logo opposite={isCollapsed} />
              </Grid>
              <Grid item>
                <MenuBtn collapsed={isCollapsed} onClick={toggle} />
              </Grid>
            </DrawerGrid>
            <Menu
              jwtToken={jwtToken}
              onBasketClick={onAnchorClick}
              onNotificationClick={showNotifications}
              documentsCount={documentsCount}
              notificationsCount={notificationsCount}
              firstName={firstName}
              lastName={lastName}
            />
          </Toolbar>
        </AppBar>
        <Sidebar
          openedWidth={drawerWidth}
          closedWidth={drawerClosedWidth}
          isOpen={!isCollapsed}
          navigation={getNavigationForUser(jwtToken, email || '')}
        />
        <MainContainer
          collapsed={isCollapsed}
          expandedWidth={drawerWidth}
          collapsedWidth={drawerClosedWidth}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </MainContainer>
        <BasketPopover anchorEl={anchor} handleClose={removeAnchor} />
      </React.Fragment>
    );
  },
);

export default connect(
  (state: ReducerState) => {
    const notifications = generateResourceSelectors(resources.NOTIFICATIONS);
    return {
      firstName: authSelectors.firstName(state),
      lastName: authSelectors.lastName(state),
      email: authSelectors.email(state),
      jwtToken: authSelectors.jwtToken(state),
      notificationsCount: notifications.results(state).length,
      documentsCount: basketSelectors.documents(state).length,
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        showNotifications: () =>
          drawerActions.showDrawer({
            title: 'Notifications',
            content: <NotificationsPanel />,
          }),
        markNotification: notificationActions.markNotification(dispatch),
      },
      dispatch,
    ),
)(AppLayout);
