import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';

export interface IconButtonProps extends MuiIconButtonProps {
  toggleButton?: boolean;
}

const IconButton = styled(MuiIconButton, {
  shouldForwardProp: (propName: string) => {
    return ['toggleButton'].indexOf(propName) === -1;
  },
})<IconButtonProps>(
  ({ theme, ...ownerState }: IconButtonProps & { theme: Theme }) => ({
    ...(ownerState.toggleButton && {
      padding: 0,
    }),
  }),
);

export default IconButton;
