const getObjectWithValues = (
  values: Record<string, any>,
): Record<string, any> => {
  if (!values) return {};
  return Object.keys(values).reduce((acc: Record<string, any>, key: string) => {
    if (values[key] instanceof Date) {
      return {
        ...acc,
        [key]: values[key],
      };
    } else if (Array.isArray(values[key]) && values[key].length > 0) {
      acc[key] = values[key].map((value: any) => {
        if (typeof value === 'object') {
          return getObjectWithValues(value);
        }
        return value;
      });
    } else if (typeof values[key] === 'object' && values[key] !== null) {
      acc[key] = getObjectWithValues(values[key]);
    } else if (values[key]) {
      acc[key] = values[key];
    }
    return acc;
  }, {} as Record<string, any>);
};

export default getObjectWithValues;
