import { call, put, select } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router';
import { authActions, crudActions, SimpleAction } from 'store/actions';
import HttpError from 'services/HttpError';
import { authSelectors } from 'store/selectors';
import { authService } from 'services';

export function* userDetailGet() {
  yield put(authActions.userDetailGet.request());
  try {
    const accessToken: string = yield select(authSelectors.accessToken);
    const { response, error } = yield call(authService.detailGet, accessToken);
    if (error) {
      yield put(replace('/sign-in'));
      return;
    }
    yield put(authActions.userDetailGet.success(response.data));
  } catch (error) {
    yield put(authActions.userDetailGet.failure(error));
  }
}

export function* refresh() {
  try {
    const refreshToken: string = yield select(authSelectors.refreshToken);
    const { response, error } = yield call(authService.tokenRefresh, {
      refresh: refreshToken,
    });
    if (error) {
      yield put(replace('/sign-in'));
      return;
    }
    yield put(authActions.signIn.success(response.data));
  } catch (error) {
    yield put(authActions.signIn.failure(error));
  }
}

export function* userAuth(
  action: SimpleAction<{
    email: string;
    password: string;
    redirectUrl?: string;
  }>,
) {
  const { payload } = action;
  const { redirectUrl, ...submitData } = payload;
  try {
    const {
      response: { data },
    } = yield call(authService.signIn, submitData);
    yield put(authActions.signIn.success(data));
    const { static_codes: staticCodes } = data;
    const finalRedirectUri = redirectUrl || '/dashboard';
    if (staticCodes) {
      yield put(
        push('/static-codes', { staticCodes, redirectUrl: finalRedirectUri }),
      );
      return;
    }
    yield put(push(finalRedirectUri));
  } catch (error) {
    if ((error as HttpError).body) {
      const body = (error as HttpError).body;
      if (body.mfa_required) {
        yield put(
          replace(`/mfa-registration`, {
            code: body.mfa_required.otp_code,
            redirectUrl,
            ...payload,
          }),
        );
      }
      if (body.totp_code_required) {
        yield put(
          replace(`/mfa-sign-in`, {
            redirectUrl,
            ...payload,
          }),
        );
      }
    }
    if ((error as HttpError).body) {
      yield put(
        authActions.signIn.failure({
          ...(error as HttpError).body,
          _error:
            (error as HttpError).body.detail ||
            (error as HttpError).body.status,
        }),
      );
      return;
    }
    yield put(authActions.signIn.failure({ _error: (error as Error).message }));
  }
}

export function* logout() {
  yield put(replace('/sign-in'));
  yield put(crudActions.cleanup.trigger());
}
