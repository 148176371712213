import React from 'react';
import {
  UseRowSelectRowProps,
  UseTableRowProps,
  TableRowProps as ReactTableRowProps,
} from 'react-table';
import { alpha, styled } from '@mui/material/styles';
import Grid from 'components/Grid';

import { Variant } from './types';
import TableCell, { TableCellProps } from './TableCell';

export interface TableRowGridProps {
  selected?: boolean;
  stripped?: boolean;
}

const TableRowGrid = styled(Grid, {
  shouldForwardProp: (propName: string) =>
    ['selected', 'stripped'].indexOf(propName) === -1,
})<TableRowGridProps>(({ theme, selected, stripped }) => ({
  color: theme.palette.warning.contrastText,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  // marginTop: theme.spacing(1),
  textAlign: 'left',
  ...(selected && {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  }),
  ...(stripped && {
    '&:nth-of-type(odd)': {
      backgroundColor: alpha(theme.palette.grey.A200, 0.19),
    },
  }),
}));

export interface TableRowProps
  extends Omit<TableCellProps, 'cell'>,
    ReactTableRowProps {
  row: UseTableRowProps<any> & UseRowSelectRowProps<any>;
  variant: Variant;
}

const TableRow = ({ variant, row, align, ...rowProps }: TableRowProps) => (
  <TableRowGrid
    item
    container
    selected={row.isSelected}
    stripped={variant === 'striped'}
    alignItems="center"
    wrap="nowrap"
    {...rowProps}
  >
    {row.cells.map((cell: any, idx: number) => (
      <TableCell align={align} cell={cell} {...cell.getCellProps()} />
    ))}
  </TableRowGrid>
);

export default TableRow;
