import React from 'react';
import { Navigation } from 'types';

interface UseNavigationProps {
  baseNavigation: Navigation;
}

const useNavigation = ({ baseNavigation }: UseNavigationProps) => {
  const [navGroups, setNavGroups] = React.useState<Array<Navigation>>([
    baseNavigation,
  ]);
  const push = React.useCallback(
    (navProps: Navigation) => {
      setNavGroups((prevNavs) => {
        return [navProps, ...prevNavs];
      });
    },
    [setNavGroups],
  );
  const pop = React.useCallback(() => {
    setNavGroups((prevNavGroups) => {
      if (prevNavGroups.length > 1) {
        return prevNavGroups.slice(1);
      }
      return prevNavGroups;
    });
  }, [setNavGroups]);
  const navProps = React.useMemo(() => {
    return navGroups[0];
  }, [navGroups]);
  const isNestedNavigation = React.useMemo(() => {
    return navGroups.length > 1;
  }, [navGroups]);
  return {
    push,
    pop,
    navProps,
    isNestedNavigation,
  };
};

export default useNavigation;
