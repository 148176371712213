export const download = async (
  fileName: string,
  content: string | Blob | MediaSource,
) => {
  const href =
    typeof content === 'string' ? content : await URL.createObjectURL(content);
  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default download;
