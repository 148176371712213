import React from 'react';
import {
  Control,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  useFieldArray as useFieldArrayHook,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldArrayPath } from 'react-hook-form/dist/types/utils';
import { FormContext } from './FormContext';

const useFieldArray = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  name,
  keyName,
}: Omit<
  UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>,
  'control'
>): UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName> => {
  const { control } = React.useContext(FormContext);
  return useFieldArrayHook<TFieldValues, TFieldArrayName, TKeyName>({
    name,
    control: control as Control<TFieldValues>,
    keyName,
  });
};

export default useFieldArray;
