import React, { MouseEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@mui/material/styles';
import MuiPopper, { PopperProps } from '@mui/material/Popper';
import MuiPaper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { useWatch } from 'contexts';
import { Button } from 'components/buttons';
import Grid from 'components/Grid';
import { ReactComponent as TickIcon } from 'assets/tick.svg';
import useChoices from '../useChoices';

export interface GroupedOption extends Record<string, any> {
  key: number;
  index: number;
  group: string;
  options: Array<Record<string, any>>;
}

interface GetOptionArgs {
  index: number;
  option: Record<string, any>;
}

export type GetOptionProps = (args: GetOptionArgs) => {
  id: string;
  key: number;
  role: string;
  onMouseOver: (evt: any) => void;
  onClick: (evt: any) => void;
  onTouchStart: () => void;
  'aria-selected': boolean;
};

interface ChoicesViewProps {
  anchor?: PopperProps['anchorEl'];
  choices: Array<GroupedOption>;
  getOptionProps: GetOptionProps;
  optionText: string;
  optionValue: string;
  onChange: (event: MouseEvent, values: any) => void;
  name: string;
}

const emptyGroup = {
  group: '',
  index: 0,
  key: 1,
  options: [],
} as GroupedOption;

const getSafeGroup = (groupName: string, choices: GroupedOption[]) => {
  if (!choices.length) {
    return emptyGroup;
  }
  const group = choices.find((choice) => choice.group === groupName);

  if (!group) {
    return choices[0];
  }

  return group;
};

const Popper = styled(MuiPopper)(() => ({
  width: 400,
  overflow: 'hidden',
  maxHeight: '40vh',
  height: '100%',
  zIndex: 1,
}));

const Paper = styled(MuiPaper)(() => ({
  margin: 0,
  overflow: 'hidden',
  height: '100%',
}));

const ChoicesView = ({
  choices,
  anchor,
  optionValue,
  optionText,
  getOptionProps,
  onChange,
  name,
}: ChoicesViewProps) => {
  const values = useWatch(name);
  const [currentGroup, setCurrentGroup] = React.useState<string>('');
  const { getChoiceText, getChoiceValue } = useChoices({
    optionValue,
    optionText,
  });
  const openOption = React.useCallback(
    (option) => () => {
      setCurrentGroup(option.group);
    },
    [setCurrentGroup],
  );

  const selectedGroup = getSafeGroup(currentGroup, choices);

  const getOptions = () => {
    if (isEmpty(values)) return [];
    return choices.reduce(
      (optionValues: Record<string, unknown>[], choice: GroupedOption) => {
        optionValues.push(
          ...choice.options.reduce(
            (
              options: Record<string, unknown>[],
              option: Record<string, unknown>,
            ) => {
              if (values.indexOf(getChoiceValue(option)) !== -1) {
                options.push(option);
              }
              return options;
            },
            [],
          ),
        );
        return optionValues;
      },
      [],
    );
  };

  const selectAll = (event: MouseEvent) => {
    const currentOptions = getOptions();
    onChange(event, [
      ...new Set([...currentOptions, ...selectedGroup.options]),
    ]);
  };

  const deselectAll = (event: MouseEvent) => {
    const currentValues = getOptions();
    const groupOptions = new Set(selectedGroup.options);

    onChange(
      event,
      [...new Set(currentValues)].filter((option) => !groupOptions.has(option)),
    );
  };

  return (
    <Popper
      open={anchor !== null}
      role="presentation"
      anchorEl={anchor}
      placement="bottom-start"
    >
      <Paper>
        <Grid container fullHeight>
          <Grid fullHeight container item xs={6} overflow="y-only">
            <List disablePadding>
              {choices.map((choice: GroupedOption) => (
                <ListItem
                  button
                  key={choice.key}
                  onClick={openOption(choice)}
                  selected={selectedGroup.group === choice.group}
                >
                  <ListItemText primary={choice.group} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid fullHeight item xs={6} overflow="y-only">
            <Grid container wrap="nowrap">
              <Grid item>
                <Button onClick={selectAll} variant="text" size="small">
                  Select all
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={deselectAll} variant="text" size="small">
                  Deselect all
                </Button>
              </Grid>
            </Grid>
            <List disablePadding>
              {selectedGroup.options.map((option, index) => {
                const props = getOptionProps({
                  index: selectedGroup.index + index,
                  option: option,
                });
                return (
                  <ListItem button {...props}>
                    <ListItemText primary={getChoiceText(option)} />
                    {props['aria-selected'] && (
                      <ListItemSecondaryAction>
                        <TickIcon />
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Popper>
  );
};

export default ChoicesView;
