import { ReducerState } from 'store/reducers';
import React from 'react';

const drawer = (state: ReducerState) => state.drawer;
const isOpen = (state: ReducerState) => drawer(state).get('isOpen');
const title = (state: ReducerState) => drawer(state).get('title');
const header = (state: ReducerState) => drawer(state).get('header');
const content = (state: ReducerState): React.ReactElement =>
  drawer(state).get('content') as React.ReactElement;

const drawerSelectors = {
  isOpen,
  title,
  header,
  content,
};

export default drawerSelectors;
