import React from 'react';
import MuiButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { alpha, styled, Theme, CSSObject } from '@mui/material/styles';
import { capitalize } from '@mui/material/utils';
import { Color } from 'types';
import {
  generateUtilityClass,
  unstable_composeClasses as composeClasses,
} from '@mui/material';

const OPACITY_VALUE = 0.19;

export type Size = 'large' | 'medium' | 'small';
export type Variant = 'contained' | 'outlined' | 'text';

export interface ButtonProps extends Omit<ButtonBaseProps, 'classes'> {
  color?: Color;
  variant?: Variant;
  children?: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  disabled?: boolean;
  disableFocusRipple?: boolean;
  focusVisibleClassName?: string;
  fullWidth?: boolean;
  href?: string;
  size?: Size;
  type?: 'button' | 'reset' | 'submit';
  textTransform?: 'uppercase' | 'capitalize' | 'none';
  opacity?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  [prop: string]: any;
}

const generateVariantStyles = ({
  theme,
  variant = 'contained',
  color = 'primary',
  opacity = false,
  size = 'medium',
}: Omit<ButtonProps, 'classes'> & { theme: Theme }) => {
  switch (variant) {
    case 'text': {
      const sizeStyles = {
        small: {
          padding: theme.spacing(0, 1),
          fontSize: 14,
          lineHeight: '16px',
        } as CSSObject,
        large: {
          fontSize: 20,
          lineHeight: '28px',
          padding: theme.spacing(0, 1),
        } as CSSObject,
        medium: {} as CSSObject,
      }[size as Size];
      const textColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        secondaryInfo: theme.palette.warning.contrastText,
      }[color as Color];
      return {
        minWidth: 0,
        padding: theme.spacing(1, 0),
        color: textColor,
        background: 'transparent',
        border: 'none',
        ...sizeStyles,
        '&:hover, &:focus': {
          fontWeight: 400,
          textDecoration: 'underline',
          boxShadow: 'none',
        },
        '&:active': {
          fontWeight: 600,
          textDecoration: 'underline',
        },
        '&:disabled': {
          color: theme.palette.text.primary,
        },
      };
    }
    case 'outlined': {
      const sizeStyles = {
        medium: {},
        small: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: 14,
          lineHeight: '16px',
        },
        large: {
          fontSize: 20,
          lineHeight: '28px',
          paddingTop: theme.spacing(1.625),
          paddingBottom: theme.spacing(1.625),
        },
      }[size as Size];
      const borderColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        secondaryInfo: theme.palette.warning.contrastText,
      }[color as Color];
      const textColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        secondaryInfo: theme.palette.text.disabled,
      }[color as Color];
      const focusBackgroundColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        secondaryInfo: theme.palette.text.disabled,
      }[color as Color];
      const focusColor = {
        primary: theme.palette.primary.contrastText,
        info: theme.palette.info.contrastText,
        success: theme.palette.success.contrastText,
        secondary: theme.palette.secondary.contrastText,
        warning: theme.palette.grey.A100,
        error: theme.palette.grey.A100,
        secondaryInfo: theme.palette.warning.contrastText,
      }[color as Color];
      const activeColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        error: theme.palette.error.main,
        secondaryInfo: theme.palette.warning.contrastText,
      }[color as Color];
      return {
        color: textColor,
        border: `1px solid ${borderColor}`,
        background: 'transparent',
        ...sizeStyles,
        '&:hover, &:focus': {
          fontWeight: 400,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.19)',
          backgroundColor: alpha(
            focusBackgroundColor,
            opacity ? OPACITY_VALUE : 1,
          ),
          color: opacity ? theme.palette.common.black : focusColor,
        },
        '&:active': {
          fontWeight: 600,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          color: activeColor,
        },
        '&:disabled': {
          color: theme.palette.common.white, //theme.palette.text.primary,
          border: '1px solid #F7F7F7',
          background: focusBackgroundColor,
        },
      };
    }
    case 'contained':
    default: {
      const sizeStyles = {
        medium: {},
        small: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: 14,
          lineHeight: '16px',
        },
        large: {
          fontSize: 20,
          lineHeight: '28px',
          paddingTop: theme.spacing(1.625),
          paddingBottom: theme.spacing(1.625),
        },
      }[size as Size];
      const backgroundColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        secondaryInfo: theme.palette.text.disabled,
        error: theme.palette.error.main,
      }[color as Color];
      const textColor = {
        primary: theme.palette.primary.contrastText,
        info: theme.palette.info.contrastText,
        success: theme.palette.success.contrastText,
        secondary: theme.palette.secondary.contrastText,
        warning: theme.palette.warning.contrastText,
        secondaryInfo: theme.palette.warning.contrastText,
        error: theme.palette.error.contrastText,
      }[color as Color];
      const backgroundFocusColor = {
        primary: theme.palette.primary.dark,
        info: theme.palette.info.dark,
        success: theme.palette.success.dark,
        secondary: theme.palette.secondary.dark,
        warning: theme.palette.warning.dark,
        secondaryInfo: theme.palette.grey.A400,
        error: theme.palette.error.dark,
      }[color as Color];
      const backgroundActiveColor = {
        primary: theme.palette.primary.main,
        info: theme.palette.info.main,
        success: theme.palette.success.main,
        secondary: theme.palette.secondary.main,
        warning: theme.palette.warning.main,
        secondaryInfo: theme.palette.text.disabled,
        error: theme.palette.error.main,
      }[color as Color];
      return {
        fontWeight: 400,
        backgroundColor: alpha(backgroundColor, opacity ? OPACITY_VALUE : 1),
        color: opacity ? theme.palette.common.black : textColor,
        ...sizeStyles,
        '&:hover, &:focus': {
          backgroundColor: alpha(
            backgroundFocusColor,
            opacity ? OPACITY_VALUE : 1,
          ),
          fontWeight: 400,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.19)',
        },
        '&:active': {
          ...(!opacity && { backgroundColor: backgroundActiveColor }),
          fontWeight: 600,
          boxShadow: 'none',
        },
        '&:disabled': {
          color: theme.palette.text.primary,
          backgroundColor: backgroundFocusColor,
        },
      };
    }
  }
};

const ButtonBase = styled(MuiButtonBase, {
  name: 'Button',
  slot: 'Root',
  shouldForwardProp: (propName: string) => {
    return (
      [
        'variant',
        'color',
        'opacity',
        'size',
        'fullWidth',
        'textTransform',
        'classes',
        'disableFocusRipple',
        'disableElevation',
      ].indexOf(propName) === -1
    );
  },
  overridesResolver: (ownerState, styles) => {
    return [
      styles.root,
      ownerState.variant && styles[ownerState.variant],
      ownerState.variant &&
        ownerState.color &&
        styles[`${ownerState.variant}${capitalize(ownerState.color)}`],
      ownerState.size && styles[`size${capitalize(ownerState.size)}`],
      ownerState.variant &&
        ownerState.size &&
        styles[`${ownerState.variant}Size${capitalize(ownerState.size)}`],
      ownerState.color === 'inherit' && styles.colorInherit,
      ownerState.disableElevation && styles.disableElevation,
      ownerState.fullWidth && styles.fullWidth,
    ];
  },
})<Omit<ButtonProps, 'classes'>>(
  ({
    theme,
    ...ownerState
  }: Omit<ButtonProps, 'classes'> & { theme: Theme }) => ({
    width: '100%',
    minWidth: 150,
    borderRadius: 30,
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1),
    boxShadow: 'none',
    ...generateVariantStyles({ ...ownerState, theme }),
  }),
);

const commonIconStyles = (ownerState: Pick<ButtonProps, 'size'>) => ({
  ...(ownerState.size === 'small' && {
    '& > *:nth-of-type(1)': {
      fontSize: 18,
    },
  }),
  ...(ownerState.size === 'medium' && {
    '& > *:nth-of-type(1)': {
      fontSize: 20,
    },
  }),
  ...(ownerState.size === 'large' && {
    '& > *:nth-of-type(1)': {
      fontSize: 22,
    },
  }),
});

const ButtonStartIcon = styled('span', {
  name: 'Button',
  slot: 'StartIcon',
  shouldForwardProp: (propName: string) => {
    return propName !== 'size';
  },
  overridesResolver: (ownerState, styles) => {
    return [styles.startIcon, styles[`iconSize${capitalize(ownerState.size)}`]];
  },
})<Pick<ButtonProps, 'size'>>(({ theme, ...ownerState }) => ({
  display: 'inherit',
  padding: theme.spacing(0, 1),
  ...commonIconStyles(ownerState),
}));

const ButtonEndIcon = styled('span', {
  name: 'Button',
  slot: 'EndIcon',
  shouldForwardProp: (propName: string) => {
    return propName !== 'size';
  },
  overridesResolver: (ownerState, styles) => {
    return [styles.endIcon, styles[`iconSize${capitalize(ownerState.size)}`]];
  },
})<Pick<ButtonProps, 'size'>>(({ theme, ...ownerState }) => ({
  display: 'inherit',
  padding: theme.spacing(0, 1),
  ...commonIconStyles(ownerState),
}));

const ButtonLabel = styled('span', {
  name: 'Button',
  slot: 'Label',
  overridesResolver: (props, styles) => {
    return [styles.label];
  },
  shouldForwardProp: (propName: string) => {
    return propName !== 'textTransform';
  },
})<Pick<ButtonProps, 'textTransform'>>(
  ({ textTransform = 'uppercase', theme }) => ({
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    textTransform,
    textAlign: 'center',
    letterSpacing: 0,
  }),
);

const getClasses = (
  variant: Variant,
  size: Size,
  color: Color,
  classes: Record<string, string>,
) => {
  const slots = {
    root: [
      'root',
      variant,
      `${variant}${capitalize(color)}`,
      `size${capitalize(size)}`,
      `${variant}Size${capitalize(size)}`,
    ],
    label: ['label'],
    startIcon: ['startIcon', `iconSize${capitalize(size)}`],
    endIcon: ['endIcon', `iconSize${capitalize(size)}`],
  };
  const getUtilityClass = (slot: string) =>
    generateUtilityClass('Button', slot);
  return composeClasses(slots, getUtilityClass, classes);
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = 'primary',
      variant = 'outlined',
      component = 'button',
      disabled = false,
      disableRipple = true,
      disableElevation = true,
      disableFocusRipple = true,
      fullWidth = false,
      size = 'medium',
      type = 'button',
      textTransform = 'uppercase',
      opacity = false,
      children,
      startIcon,
      endIcon,
      classes,
      className,
      ...other
    }: ButtonProps,
    ref,
  ) => {
    const ownerState = {
      color,
      component,
      variant,
      disabled,
      disableRipple,
      disableElevation,
      disableFocusRipple,
      fullWidth,
      size,
      textTransform,
      opacity,
      className,
      ...other,
    };
    const composedClasses = getClasses(variant, size, color, classes);
    return (
      <ButtonBase
        ref={ref}
        {...ownerState}
        component={component}
        disabled={disabled}
        focusRipple={!disableFocusRipple}
        type={type}
      >
        {startIcon && (
          <ButtonStartIcon size={size}>{startIcon}</ButtonStartIcon>
        )}
        <ButtonLabel
          className={composedClasses ? composedClasses.label : undefined}
          textTransform={textTransform}
        >
          {children}
        </ButtonLabel>
        {endIcon && <ButtonEndIcon size={size}>{endIcon}</ButtonEndIcon>}
      </ButtonBase>
    );
  },
);

export default Button;
