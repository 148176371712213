import React from 'react';
import { RegisterOptions } from 'react-hook-form';
import { DocumentType } from 'types/rest';
import { GridDirection } from 'components/Grid';

export type Order = 'asc' | 'desc';

export interface BaseResource {
  id: number | string;

  [k: string]: any;
}

export interface Sort {
  field: string;
  order: Order;
}

export interface SortItem {
  sortBy: Sort;
  label: string;
}

export interface Filter {
  [k: string]: any;
}

export interface AppliedFilter {
  id: string;
  values: Record<string, any> | Array<string> | null;
}

export interface Pagination {
  page: number;
  perPage: number;
}

export interface Query {
  filters: AppliedFilters;
  sort: Array<Sort>;
  pagination: Pagination;
}

export interface AutocompleteConfig {
  resource?: string;
  optionValue?: string;
  optionText?: string;
  setFilter?: (values: any) => any;
}

export interface UpdateInPlace {
  updateInPlace: (index: number, id: string, value: any) => void;
}

export type Parser = (val: string) => any;
export type Formatter = (val: any) => string;

export enum FilterType {
  Text = 'text',
  Autocomplete = 'autocomplete',
  DateInput = 'dateInput',
  DateRangePicker = 'dateRangePicker',
  Select = 'select',
  Radio = 'radio',
  GroupFilter = 'groupFilter',
  Range = 'range',
  SelectInput = 'selectInput',
}

export interface FilterConfig {
  label: string;
  description?: string;
  type: FilterType;
  config?: AutocompleteConfig;
  enabled?: boolean;
  choices?: Array<any>;
  many?: boolean;
  hidden?: boolean;
  format?: Formatter;
  parser?: Parser;
  idField?: string;
  group?: string;
}

export type Selector<T extends any> = (state: any) => T;

export type AppliedFilters = Record<
  string,
  Array<any> | Record<string, any> | any
>;

export interface FetchPayload {
  filters: AppliedFilters;
  pagination: Pagination;
  sort: Array<Sort>;
}

export interface FetchDetail {
  id: number | string;
}

export enum SummaryItemType {
  Grid = 'grid',
  Country = 'country',
  Chip = 'chip',
  Tag = 'tag',
}

export type FieldType =
  | 'date'
  | 'daterange'
  | 'autocomplete'
  | 'arrayautocomplete'
  | 'input';

export interface FieldProps {
  name: string;
  type: FieldType;
  additionalProps?: Record<string, any>;
}

export interface SummaryItem {
  type: SummaryItemType;
  direction: GridDirection;
  label: string;
  field: string | ((value: any) => React.ReactNode);
  fieldProps?: FieldProps;
  show_in?: Array<string>;
  hide_in?: Array<string>;
  options?: Record<string, any>;
}

export interface SummaryGroup {
  title?: string;
  direction: GridDirection;
  items: Array<SummaryItem>;
  show_in?: Array<string>;
}

export interface Document {
  doc_types: Array<DocumentType>;

  [prop: string]: any;
}

export interface AdvancedSearch {
  all_words: string;
  any_words: string;
  exact_phrase: string;
  exclude_words: string;
  document_name: string;
}

export interface NavigationItem {
  title: string;
  icon?: React.ElementType;
  link?: string;
  anchor?: string;
  subItems?: Array<NavigationItem>;
  nestedNav?: Navigation;
  spacing?: number;
}

export type NavigationGroup = Array<NavigationItem>;

export interface Navigation {
  topNavGroups?: Array<NavigationGroup>;
  bottomNavGroups?: Array<NavigationGroup>;
}

export type Rules = Exclude<
  RegisterOptions,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs'
>;

export interface BaseInputProps {
  rules?: Rules;
  helpText?: string;
  label?: string;
  tip?: string;
}

export type OnSubmit = (values: Record<string, any>) => void;

export type Color =
  | 'primary'
  | 'info'
  | 'success'
  | 'secondary'
  | 'warning'
  | 'secondaryInfo'
  | 'error';
