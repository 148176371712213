import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useInput } from 'contexts';
import { drawerActions } from 'store/actions';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import AvatarGroup from 'components/AvatarGroup';
import { BaseInputProps } from 'types';
import Input from '../Input/Input';
import ResourceItemsPanel, {
  DynamicResourceItemsPanelProps,
  OnSelect,
} from '../ResourcePanel';

interface ResourceSelectInputProps
  extends BaseInputProps,
    Partial<DynamicResourceItemsPanelProps> {
  resource: DynamicResourceItemsPanelProps['resource'];
  columns: DynamicResourceItemsPanelProps['columns'];
  chooseSources?: (onSelect: OnSelect, value?: Record<string, any>[]) => void;
  optionName: string;
  name: string;
}

const ResourceSelectInput = ({
  chooseSources,
  label,
  optionName,
  name,
  rules,
}: ResourceSelectInputProps) => {
  const { field, meta, error } = useInput({ name, rules, defaultValue: [] });
  const onShowSourcesClick = React.useCallback(() => {
    chooseSources &&
      chooseSources((items: any) => {
        field.onChange(items);
      });
  }, [field, chooseSources]);

  const getContent = React.useCallback(
    (item: Record<string, any>) => {
      return get(item, optionName, '')
        .split(' ')
        .map((val: string) => (val.length > 0 ? val[0] : ''));
    },
    [optionName],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Input
          onClick={onShowSourcesClick}
          label={label}
          error={error}
          field={{ ...field, value: '' }}
          meta={meta}
        />
      </Grid>
      <Grid item>
        <AvatarGroup max={4}>
          {field.value.map((item: Record<string, any>, idx: number) => (
            <Avatar key={idx} content={getContent(item)} />
          ))}
        </AvatarGroup>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  (
    dispatch,
    {
      label,
      resource,
      filters,
      columns,
      hasUpdateInPlace,
      idField,
    }: ResourceSelectInputProps,
  ) =>
    bindActionCreators(
      {
        chooseSources: (onSelect: OnSelect, value?: Record<string, any>[]) => {
          return drawerActions.showDrawer({
            title: label,
            content: (
              <ResourceItemsPanel
                idField={idField}
                initialData={value}
                hasUpdateInPlace={hasUpdateInPlace}
                filters={filters}
                onSelect={onSelect}
                resource={resource}
                columns={columns}
              />
            ),
          });
        },
      },
      dispatch,
    ),
)(ResourceSelectInput);
