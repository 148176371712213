import React, { ReactNode, useCallback, useState } from 'react';
import PopoverContext, { PopoverItem } from './PopoverContext';
import { PopoverDisplay } from './displayPopover';

export interface PopoverProviderProps {
  children: ReactNode;
}

const PopoverProvider = ({ children }: PopoverProviderProps) => {
  const [popovers, setPopovers] = useState<Record<string, PopoverItem>>({});
  const showPopover = useCallback(
    (popoverItem: PopoverItem) => {
      setPopovers((prevState) => ({
        ...prevState,
        [popoverItem.name]: popoverItem,
      }));
      if (popoverItem.onShow) {
        popoverItem.onShow();
      }
    },
    [setPopovers],
  );
  const closePopover = useCallback(
    (popoverItem: PopoverItem) => {
      setPopovers((prevState) =>
        Object.fromEntries(
          Object.entries(prevState).filter(
            ([name]) => name !== popoverItem.name,
          ),
        ),
      );
      if (popoverItem.onClose) {
        popoverItem.onClose();
      }
    },
    [setPopovers],
  );
  const close = useCallback(
    (popover: PopoverItem) => () => closePopover(popover),
    [closePopover],
  );

  return (
    <PopoverContext.Provider value={{ showPopover, closePopover }}>
      {Object.values(popovers).map((popoverItem) => (
        <PopoverDisplay
          key={popoverItem.name}
          popoverItem={popoverItem}
          close={close}
        />
      ))}
      {children}
    </PopoverContext.Provider>
  );
};

export default PopoverProvider;
