import { ReactNode, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Grid from 'components/Grid';
import DrawerHeader from 'components/LeftSideDrawer/DrawerHeader';
import DrawerContext, { DrawerContainerProps } from './DrawerContext';
import { Theme } from 'types';

export interface DrawerProviderProps {
  children: ReactNode;
}

const DrawerContainer = styled(Grid)(({ theme }) => ({
  flex: 1,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => ['width'].indexOf(prop) === -1,
})(({ theme, width }: { width: string | undefined; theme?: Theme }) => ({
  zIndex: theme!.zIndex.drawer + 2,
  '& .MuiDrawer-paperAnchorRight': {
    width: width,
  },
}));

const ContentContainer = styled(Grid)(() => ({
  flex: 1,
  height: 'calc(100vh - 130px)',
}));

const DEFAULT_DRAW_STATE = {
  open: false,
  header: false,
  content: false,
  padding: 5,
  width: '50vw',
};

const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [config, setConfig] =
    useState<DrawerContainerProps>(DEFAULT_DRAW_STATE);
  const close = () => {
    setConfig((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const show = (drawerConfig: DrawerContainerProps) => {
    setConfig({
      ...DEFAULT_DRAW_STATE,
      ...drawerConfig,
      open: true,
    });
  };
  return (
    <DrawerContext.Provider value={{ show, close }}>
      <Drawer
        anchor="right"
        width={config.width}
        open={config.open}
        onClose={close}
      >
        <DrawerContainer container padding={config.padding} direction="column">
          {config.header &&
            (typeof config.header === 'string' ? (
              <DrawerHeader title={config.header} close={close} />
            ) : (
              config.header
            ))}
          {config.content && (
            <ContentContainer item container>
              {config.content}
            </ContentContainer>
          )}
        </DrawerContainer>
      </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
