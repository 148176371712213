import { Empty, PageQuery, PageResponse, request } from 'services/request';
import { Workflow } from 'types';

interface WorkflowListQuery extends PageQuery {
  organization_id: string | number;
}

const list = ({ ...query }: WorkflowListQuery, token: string) =>
  request<PageQuery, PageResponse<Workflow>, Empty>(
    'organization/workflows/',
    {
      method: 'GET',
      query,
    },
    token,
  );

const create = ({ ...payload }: Workflow, token: string) =>
  request<Workflow, Workflow, Empty>(
    'organization/workflows/',
    {
      payload,
      method: 'POST',
    },
    token,
  );

const detail = ({ id }: Workflow, token: string) =>
  request<Empty, Workflow, Empty>(
    `organization/workflows/${id}/`,
    {
      method: 'GET',
    },
    token,
  );

const update = ({ id, ...payload }: Partial<Workflow>, token: string) =>
  request<Partial<Workflow>, Workflow, Empty>(
    `organization/workflows/${id}/`,
    {
      method: 'PATCH',
      payload,
    },
    token,
  );

const remove = ({ id }: Workflow, token: string) =>
  request<Empty, Empty, Empty>(
    `organization/workflows/${id}/`,
    {
      method: 'DELETE',
    },
    token,
  );

const workflowServices = {
  list,
  create,
  detail,
  update,
  remove,
};

export default workflowServices;
