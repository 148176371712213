import React from 'react';
import { LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Link from 'components/Link';

const StyledLink = styled(Link)(() => ({
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

const isExternalLink = (link: string) => {
  const absolutePattern = /^https?:\/\//i;
  return absolutePattern.test(link);
};

interface LinkWrapperProps extends LinkProps {
  to: string;
}

const LinkWrapper = ({ to, children }: LinkWrapperProps) => {
  if (to) {
    return (
      <StyledLink
        to={isExternalLink(to) ? { pathname: to } : to}
        target={isExternalLink(to) ? '_blank' : undefined}
        rel={isExternalLink(to) ? 'noreferrer noopener' : undefined}
      >
        {children}
      </StyledLink>
    );
  }
  return children as React.ReactElement;
};

export default LinkWrapper;
