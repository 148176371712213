import React from 'react';
import get from 'lodash/get';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormContext } from './FormContext';
import parseServerError from 'contexts/Form/parseServerError';

const useInput = ({
  name,
  defaultValue = '',
  ...props
}: Omit<UseControllerProps, 'control'>) => {
  const { control, errors, serverErrors } = React.useContext(FormContext);
  const { field, fieldState: meta } = useController({
    ...props,
    name,
    control,
    defaultValue,
  });
  const error = get(
    errors,
    name,
    serverErrors
      ? {
          message: parseServerError(name, serverErrors),
        }
      : undefined,
  );
  return {
    field,
    meta,
    error: error ? error.message : undefined,
  };
};

export default useInput;
