import { FilterConf, FilterType } from 'types';
import resources from 'store/resources';

const tasksFilters: FilterConf = new Map([
  [
    'assignee',
    {
      label: 'Assignee',
      type: FilterType.Autocomplete,
      config: {
        resource: resources.USERS,
        optionValue: 'id',
        optionText: 'email',
      },
      enabled: true,
      many: false,
    },
  ],
  [
    'workflow_id',
    {
      label: 'Workflow',
      type: FilterType.Autocomplete,
      enabled: true,
      many: false,
      config: {
        resource: resources.WORKFLOW,
        optionValue: 'id',
        optionText: 'name',
      },
    },
  ],
  [
    'status',
    {
      label: 'Status',
      type: FilterType.Text,
      enabled: true,
      many: true,
    },
  ],
  [
    'due_date',
    {
      label: 'Due Date',
      type: FilterType.DateRangePicker,
      enabled: true,
    },
  ],
]);

export default tasksFilters;
