import React from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import config from 'config';

const daysInMonth = (month: number, year: number | null = null) => {
  if (!year) {
    return [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
  }
  return new Date(year, month, 0).getDate();
};

interface DateFormatInputProps extends NumberFormatProps {
  [prop: string]: any;
}

const DateFormatInput = React.forwardRef(
  ({ onChange, name, ...rest }: DateFormatInputProps, ref) => {
    const inputMode = React.useRef<boolean>(false);
    const [numberFormatValues, setNumberFormatValues] =
      React.useState<NumberFormatValues>({
        floatValue: undefined,
        formattedValue: '',
        value: '',
      });
    const isAllowed = React.useCallback((values) => {
      const [dayStr, monthStr, yearStr] = values.formattedValue
        .split('/')
        .map((v: string) => v.trim());
      const [day, month, year] = [dayStr, monthStr, yearStr].map((v: string) =>
        parseInt(v),
      );
      const dayIsValid = dayStr && dayStr.length === 2;
      const monthIsValid = monthStr && monthStr.length === 2;
      const yearIsValid = yearStr && yearStr.length === 4;

      if (!dayIsValid || !monthIsValid || !yearIsValid) {
        return true;
      }

      if (dayIsValid && (day < 1 || day > 31)) {
        return false;
      }

      if (
        dayIsValid &&
        monthIsValid &&
        (month < 1 || month > 12 || daysInMonth(month) < day)
      ) {
        return false;
      }

      if (yearIsValid && (year < 1 || daysInMonth(month, year) < day)) {
        return false;
      }

      return true;
    }, []);

    const onValueChange = React.useCallback(
      (values) => {
        setNumberFormatValues(values);

        if (values.value.length === 8 && onChange && inputMode.current) {
          onChange({
            target: {
              name: name,
              value: values.formattedValue,
            },
          } as React.ChangeEvent<HTMLInputElement>);
        }
      },
      [setNumberFormatValues, name, onChange, inputMode],
    );

    const onBlur = React.useCallback(
      (evt) => {
        inputMode.current = false;
        if (rest.onBlur) {
          let blurEvt = evt;
          if (numberFormatValues.value.length !== 8) {
            blurEvt = {
              ...evt,
              target: {
                ...evt.target,
                value: rest.value,
              },
            };
          }
          rest.onBlur(blurEvt);
        }
      },
      [inputMode, rest, numberFormatValues],
    );
    const onFocus = React.useCallback(
      (evt) => {
        inputMode.current = true;
        if (rest.onFocus) {
          rest.onFocus(evt);
        }
      },
      [inputMode, rest],
    );

    React.useEffect(() => {
      if (inputMode.current) return;
      if (rest.value !== numberFormatValues.formattedValue) {
        setNumberFormatValues({
          ...numberFormatValues,
          value: (rest.value as string).split('/').join(),
          formattedValue: rest.value as string,
        });
      }
    }, [inputMode, setNumberFormatValues, numberFormatValues, rest]);

    return (
      <NumberFormat
        {...rest}
        isAllowed={isAllowed}
        value={numberFormatValues.value}
        getInputRef={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
        format="##/##/####"
        placeholder={config.dateFormat}
      />
    );
  },
);

export default DateFormatInput;
