import React from 'react';
import { Theme, alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Notification } from 'types';
import { formatTodayDate } from 'utils';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { ReactComponent as StatusColorIcon } from 'assets/statusColor.svg';
import { ReactComponent as DoubleIndicatorIcon } from 'assets/doubleIndicator.svg';

interface Props {
  item: Notification;
  onClick: (item: Notification) => void;
}

interface NotificationItemBodyProps {
  unread: boolean;
}

const IconSuccess = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const UnreadIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const NotificationItemBody = styled(Box, {
  shouldForwardProp: (propName: string) => propName !== 'unread',
})<NotificationItemBodyProps>(
  ({ theme, ...ownerState }: NotificationItemBodyProps & { theme: Theme }) => ({
    maxHeight: 220,
    overflow: 'auto',
    padding: theme.spacing(4, 2, 1),
    marginBottom: theme.spacing(0.5),
    background: alpha(theme.palette.text.disabled, 0.19),
    ...(ownerState.unread && {
      cursor: 'pointer',
    }),
  }),
);

const NotificationItem = ({ item, onClick }: Props) => {
  const { verb, description, timestamp, unread } = item;

  const clickHandler = React.useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <NotificationItemBody unread={unread} onClick={clickHandler}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography bottomSpacing={2}>
            <b>{verb}</b>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{formatTodayDate(timestamp)}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={10}>
          <Typography>{description}</Typography>
        </Grid>
        <Grid item>
          {!unread ? (
            <IconSuccess>
              <DoubleIndicatorIcon />
            </IconSuccess>
          ) : (
            <UnreadIcon>
              <StatusColorIcon />
            </UnreadIcon>
          )}
        </Grid>
      </Grid>
    </NotificationItemBody>
  );
};

export default NotificationItem;
