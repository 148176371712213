import React from 'react';
import List from '@mui/material/List';
import { NavigationItem } from 'types';
import NavItem, { NavItemProps } from './NavItem';

export interface NavListProps {
  extendedMode: boolean;
  topNav?: boolean;
  bottomNav?: boolean;
  nested?: boolean;
  navItems: Array<NavigationItem>;
  pushNavigation: NavItemProps['pushNavigation'];
}

const NavList = ({
  extendedMode,
  topNav,
  bottomNav,
  navItems,
  nested = false,
  pushNavigation,
}: NavListProps) => (
  <List
    sx={{
      display: 'flex',
      flexDirection: 'column',
      ...(topNav && {
        padding: 0,
        paddingTop: 3,
        paddingBottom: 3,
        flex: `0 0 auto`,
      }),
      ...(bottomNav && {
        justifyContent: 'flex-end',
      }),
    }}
  >
    {navItems.map((item, idx) => (
      <NavItem
        nested={nested}
        extendedMode={extendedMode}
        key={idx}
        item={item}
        pushNavigation={pushNavigation}
      />
    ))}
  </List>
);

export default NavList;
