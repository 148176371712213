import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';
import ColorWrap, {
  ExportedColorProps,
  InjectedColorProps,
} from 'react-color/lib/components/common/ColorWrap';
import { styled } from '@mui/material/styles';
import Grid from 'components/Grid';
import HuePointer from './HuePointer';
import SaturationPointerCircle from './SaturationPointer';

const PickerContainer = styled(Grid)(({ theme }) => ({
  width: 255,
  background: theme.palette.background.paper,
  boxShadow: '0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)',
  boxSizing: 'initial',
}));

const SaturationContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  paddingBottom: '55%',
  position: 'relative',
  overflow: 'hidden',
}));

const HueContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(1, 4),
  padding: theme.spacing(1),
}));

const InputContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2, 'auto'),
}));

const Picker = (props: InjectedColorProps & ExportedColorProps) => {
  const hueProps = { hsl: props.hsl, onChange: props.onChange };
  return (
    <PickerContainer container direction="column">
      <SaturationContainer item>
        <Saturation {...(props as any)} pointer={SaturationPointerCircle} />
      </SaturationContainer>
      <HueContainer item>
        <Hue {...(hueProps as any)} pointer={HuePointer} />
      </HueContainer>
      <InputContainer item container justifyContent="center">
        <EditableInput value={props.hex} onChange={props.onChange} />
      </InputContainer>
    </PickerContainer>
  );
};

export default ColorWrap(Picker);
