import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppliedFilters, Query, ResourceRecord, Sort } from 'types';

import { ReducerState } from 'store/reducers';
import { getResource } from 'store/selectors/utils';
import { crudActions } from 'store/actions';

import useReferenceInputController from './useReferenceArrayInput';

interface ReferenceInputProps {
  children: React.ReactNode;
  source: string;
  resource: ResourceRecord;
  name: string;
  debounceTimeout?: number;
  queryField?: string;
  idField?: string;
  perPage?: number;
  filters?: AppliedFilters;
  sort?: Sort[];
  onChange?: (value: Record<string, any>) => void;
  getMany: (query: Query) => void;
  prepareChoices?: (choices: Record<string, any>[]) => Record<string, any>[];
}

const ReferenceArrayInput = ({
  name,
  filters,
  idField = 'id',
  queryField = 'name',
  perPage,
  resource,
  sort = [],
  getMany,
  children,
  onChange,
  debounceTimeout,
  prepareChoices,
}: ReferenceInputProps) => {
  const inputProps = useReferenceInputController({
    name,
    idField,
    filters,
    queryField,
    perPage,
    resource,
    sort,
    getMany,
    onChange,
    debounceTimeout,
  });

  return React.cloneElement(children as React.ReactElement, {
    ...(children as React.ReactElement).props,
    ...inputProps,
  });
};

export default connect(
  (
    rootReducer: ReducerState,
    { source }: Pick<ReferenceInputProps, 'source'>,
  ) => ({
    resource: getResource(source)(rootReducer),
  }),
  (dispatch, { source }: Pick<ReferenceInputProps, 'source'>) =>
    bindActionCreators(
      {
        getMany: (query: Query) =>
          crudActions.getMany({
            ...query,
            meta: {
              resource: source,
            },
          }),
      },
      dispatch,
    ),
)(ReferenceArrayInput);
