import React from 'react';
import moment, { Moment } from 'moment';
import Popover from '@mui/material/Popover';
import config from 'config';
import CalendarPicker from './CalendarPicker';
import { RenderDay } from './types';

interface DatePickerPopoverProps {
  onClose: () => any;
  anchor: HTMLElement | null;
  value: Moment | null;
  onChange: (date: moment.Moment) => any;
  renderDay: RenderDay;
}

const DatePickerPopover = ({
  anchor,
  value,
  onClose,
  onChange,
  renderDay,
}: DatePickerPopoverProps) => {
  const initValue = React.useRef<boolean>(false);
  const skipDate = React.useRef<boolean>(false);
  const [currentValue, setValue] = React.useState(value);

  const onYearChange = React.useCallback(() => {
    skipDate.current = true;
  }, [skipDate]);

  const safeOnChange = React.useCallback(
    (date: unknown) => {
      const selectDate = date as moment.Moment | null;
      if (skipDate.current) {
        skipDate.current = false;
        setValue(selectDate);
        return;
      }
      if (selectDate) {
        onChange(selectDate);
      }
    },
    [skipDate, setValue, onChange],
  );

  React.useEffect(() => {
    if (!initValue.current && !!anchor) {
      setValue(value);
      initValue.current = true;
    }
  }, [anchor, initValue, setValue, value]);

  React.useEffect(() => {
    if (!anchor) {
      initValue.current = false;
    }
  }, [anchor]);
  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <CalendarPicker
        maxDate={config.maxDate}
        minDate={config.minDate}
        date={currentValue}
        onYearChange={onYearChange}
        onChange={safeOnChange}
        renderDay={renderDay}
      />
    </Popover>
  );
};

export default DatePickerPopover;
