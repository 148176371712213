import { ReducerState } from 'store/reducers';
import { JWTToken } from 'types';

const auth = (state: ReducerState) => state.auth;

const id = (state: ReducerState) => auth(state).get('id');
const accessToken = (state: ReducerState) => auth(state).get('access');
const refreshToken = (state: ReducerState) => auth(state).get('refresh');
const firstName = (state: ReducerState) => auth(state).get('first_name');
const lastName = (state: ReducerState) => auth(state).get('last_name');
const email = (state: ReducerState) => auth(state).get('email');
const username = (state: ReducerState) => auth(state).get('username');
const errors = (state: ReducerState) => auth(state).get('errors');
const isSubmitting = (state: ReducerState) => auth(state).get('isSubmitting');
const isLoading = (state: ReducerState) => auth(state).get('isLoading');
const isAuthenticated = (state: ReducerState) => accessToken(state) != null;
const jwtToken = (state: ReducerState): JWTToken => {
  const emptyToken = {
    header: {},
    payload: {},
  } as JWTToken;
  const token = accessToken(state);
  try {
    if (!token) return emptyToken;
    const [header, payload] = token
      .split('.')
      .slice(0, -1)
      .map((part) => JSON.parse(atob(part)));
    return {
      header,
      payload,
    } as JWTToken;
  } catch (e) {
    return emptyToken;
  }
};
const isWaymarkStaff = (state: ReducerState) =>
  jwtToken(state).payload.is_staff || jwtToken(state).payload.is_superuser;

const authSelectors = {
  id,
  accessToken,
  refreshToken,
  firstName,
  lastName,
  email,
  errors,
  username,
  isAuthenticated,
  jwtToken,
  isSubmitting,
  isLoading,
  isWaymarkStaff,
};

export default authSelectors;
