import React from 'react';
import { styled } from '@mui/material/styles';
import MuiInput, { InputProps as BaseInputProps } from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as ClearIcon } from 'assets/close.svg';

interface InputProps extends BaseInputProps {
  clear?: () => void;
  clearable?: boolean;
}

const InputEndAdornment = styled('div')({
  position: 'absolute',
  right: 20,
  top: 'calc(50% - 10px)',
});

const StyledIconButton = styled(IconButton)({
  maxWidth: 20,
  visibility: 'hidden',
});

const StyledInput = styled(MuiInput)({
  '&:hover': {
    color: '#555',
    'button:last-child': {
      visibility: 'visible',
    },
  },
});

const Input = ({ clear, clearable, ...other }: InputProps) => (
  <StyledInput
    endAdornment={
      clearable && (
        <InputEndAdornment>
          <StyledIconButton onClick={clear} size="small">
            <ClearIcon />
          </StyledIconButton>
        </InputEndAdornment>
      )
    }
    {...other}
  />
);

export default Input;
