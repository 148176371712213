import React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

export interface DrawerHeaderProps {
  title: string;
  close: () => void;
}

const DrawerHeader = ({ title, close }: DrawerHeaderProps) => (
  <Grid
    item
    container
    justifyContent="space-between"
    alignItems="center"
    sx={{ flex: '0 0 50px' }}
  >
    <Grid item>
      <Typography variant="h3">{title}</Typography>
    </Grid>
    <Grid item>
      <IconButton title="close" onClick={close} size="large">
        <CloseIcon />
      </IconButton>
    </Grid>
  </Grid>
);

export default DrawerHeader;
