import { createRoutine } from './utils';

export const signIn = createRoutine('SIGN_IN');
export const signUp = createRoutine('SIGN_UP');
export const logout = createRoutine('LOGOUT');
export const activateUserAccount = createRoutine('ACTIVATE_USER_ACCOUNT');
export const userDetailGet = createRoutine('USER_DETAIL_GET');
export const userDetailUpdate = createRoutine('USER_DETAIL_UPDATE');
export const resetPassword = createRoutine('RESET_PASSWORD');
export const resetPasswordConfirm = createRoutine('RESET_PASSWORD_CONFIRM');

export interface SignInPayload {
  username: string;
  password: string;
}

export interface SignUpPayload extends SignInPayload {
  email: string;
  first_name: string;
  last_name: string;
}

export interface UserDetailPayload {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
}

const authActions = {
  signIn,
  signUp,
  logout,
  activateUserAccount,
  userDetailGet,
  userDetailUpdate,
  resetPassword,
  resetPasswordConfirm,
};

export default authActions;
