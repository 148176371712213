import React from 'react';
import { useInput } from 'contexts';
import Input, { InputProps } from './Input';

export interface FormInputProps
  extends Omit<InputProps, 'field' | 'meta' | 'error'> {
  name: string;
}

const FormInput = ({
  name,
  rules,
  many,
  defaultValue = many ? [] : '',
  ...props
}: FormInputProps) => {
  const { field, error, meta } = useInput({
    name,
    rules,
    defaultValue,
  });
  return (
    <Input {...props} many={many} field={field} meta={meta} error={error} />
  );
};

export default FormInput;
