import { forwardRef, ReactChild, Ref } from 'react';
import { UseControllerReturn } from 'react-hook-form';
import MuiFormControl, {
  FormControlProps as MuiFormControlProps,
} from '@mui/material/FormControl';
import { styled, Theme } from '@mui/material/styles';

import { WARNING_CONTRAST_TEXT_COLOR } from 'theme/constants';

import LoadingIndicator from './Loader';
import FieldHelper, { HelperTextProps } from './FieldHelper';
import InputTooltip from './InputTooltip';
import InputLabel, { InputLabelProps } from './InputLabel';

export interface FormControlProps extends MuiFormControlProps {
  hidden?: boolean;
}

const FormControl = styled(MuiFormControl, {
  shouldForwardProp: (propName: string) => {
    return ['hidden'].indexOf(propName) === -1;
  },
})<FormControlProps>(
  ({ theme, ...ownerState }: FormControlProps & { theme: Theme }) => ({
    boxShadow: 'none',
    ...(ownerState.hidden && {
      display: 'none',
    }),
    color: WARNING_CONTRAST_TEXT_COLOR,
  }),
);

export interface FormInputControlProps extends Omit<FormControlProps, 'error'> {
  label?: ReactChild;
  required?: boolean;
  name?: string;
  meta: UseControllerReturn['fieldState'];
  tip?: ReactChild;
  loading?: boolean;
  error?: string;
  helpText?: ReactChild;
  InputLabelProps?: InputLabelProps;
  HelperTextProps?: HelperTextProps;
}

const FormInputControl = forwardRef(
  (
    {
      label,
      required,
      name,
      meta,
      tip,
      loading,
      children,
      helpText,
      error,
      HelperTextProps,
      InputLabelProps,
      ...props
    }: FormInputControlProps,
    ref,
  ) => (
    <FormControl
      fullWidth
      margin="normal"
      {...props}
      ref={ref as Ref<HTMLDivElement>}
    >
      {label ? (
        <InputLabel
          shrink
          required={required}
          htmlFor={name}
          labelError={meta.isTouched && meta.invalid}
          labelSuccess={meta.isTouched && !meta.invalid}
          {...InputLabelProps}
        >
          {label}
        </InputLabel>
      ) : null}
      {tip && <InputTooltip title={tip} />}
      {loading ? <LoadingIndicator loading /> : children}
      <FieldHelper
        HelperTextProps={HelperTextProps}
        meta={meta}
        error={error}
        helpText={helpText}
      />
    </FormControl>
  ),
);

export default FormInputControl;
