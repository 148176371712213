import Immutable from 'immutable';
import { Group, ResourceRecord, ResourceReducer } from 'types';
import { groupsFilters } from 'store/configs';
import resources from 'store/resources';
import { createResourceReducer } from './utils';

export type GroupsState = ResourceRecord<Group>;

export const InitialGroupsState = Immutable.Record<ResourceReducer<Group>>({
  filtersConf: groupsFilters,
  filters: {},
  sort: [],
  isLoading: false,
  count: 0,
  pagination: {
    perPage: 10,
    page: 1,
  },
  currentItem: null,
  autocomplete: [],
  results: [],
  errors: {},
});

export default createResourceReducer({
  resource: resources.GROUPS,
  initialState: new InitialGroupsState(),
});
