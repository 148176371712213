import { ReactComponent as WaymarkLogoIcon } from 'assets/white_logo.svg';
import { styled } from '@mui/material/styles';

const Logo = styled(WaymarkLogoIcon, {
  shouldForwardProp: (propName: string) => propName !== 'opposite',
})<{ opposite: boolean }>(({ theme, opposite }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  opacity: 1,
  height: 80,
  width: 184,
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  ...(opposite && {
    opacity: 0,
    left: -999,
    top: 11,
  }),
}));

export default Logo;
