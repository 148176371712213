import { createContext } from 'react';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { FormContextValue } from './types';

export const FormContext = createContext<FormContextValue>({
  formState: {},
  errors: {},
  serverErrors: {},
  trigger: () => null,
  setValue: () => {},
  getValues: () => [],
  watch: (...args: any) => args,
  reset: () => null,
  register: (name: string, opts: RegisterOptions) => null,
  clearErrors: () => null,
} as unknown as FormContextValue);
