import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import { AppliedFilters, Pagination, Sort } from 'types';

import { getService, ServiceFn } from 'services';
import HttpError from 'services/HttpError';

import { CRUDAction, ResourcePayload } from 'store/actions/crud';
import { authSelectors } from 'store/selectors';
import { crudActions } from 'store/actions';
import { refresh } from 'store/sagas/auth';
import { generateResourceSelectors } from 'store/selectors/utils';

const PER_PAGE = 25;
const MAX_ATTEMPTS = 5;
const HTTP_400_GENERAL_ERROR_MESSAGE =
  'An unexpected error has occurred (400). Please check your data and try again or contact support.';

const isValid = (value: any) => {
  if (isNil(value)) return false;

  if (isString(value)) return !isEmpty(value);
  if (isArray(value)) return value.length > 0;

  return true;
};

function* callService(
  resource: string,
  type: string,
  payload: Record<string, any>,
  partial: boolean = false,
) {
  let copyError;
  const service: ServiceFn = yield call(getService, resource, type);
  for (let attempt = 0; attempt < MAX_ATTEMPTS; attempt++) {
    try {
      const accessToken: string = yield select(authSelectors.accessToken);
      return (yield call<ServiceFn>(
        service,
        payload,
        accessToken,
        partial,
      )) as Record<string, any>;
    } catch (error) {
      copyError = error;
      if (error instanceof HttpError) {
        switch (error.status) {
          case 401:
          case 403:
            yield call(refresh);
            break;
          case 404:
            throw error;
          case 500:
            alert('server error');
            throw error;
          default:
            throw error;
        }
      }
    }
  }
  yield put(replace('/sign-in'));
  throw copyError;
}

function* handleCRUDAction(action: CRUDAction) {
  const {
    type,
    payload,
    meta: { partial, resource },
  } = action;
  return (yield call(callService, resource, type, payload, partial)) as Record<
    string,
    any
  >;
}

function* triggerListUpdate(resource: string) {
  const resourceSelector = generateResourceSelectors(resource);
  const filters: AppliedFilters = yield select(resourceSelector.filters);
  const pagination: Pagination = yield select(resourceSelector.pagination);
  const sort: Sort = yield select(resourceSelector.sort);
  yield put(
    crudActions.list.trigger({ filters, pagination, sort, meta: { resource } }),
  );
}

function* handleMetaOnSuccess(action: CRUDAction) {
  const { payload, meta } = action;
  if (meta && meta.onSuccess) {
    try {
      const {
        runListUpdate,
        redirectTo,
        relatedRedirect,
        callback,
        extra,
        notification,
      } = meta.onSuccess;
      if (runListUpdate && meta.resource) {
        yield call(triggerListUpdate, meta.resource);
      }
      if (redirectTo || relatedRedirect || callback || notification) {
        yield put({
          type: 'SIDE_EFFECT',
          payload,
          meta: {
            relatedRedirect,
            redirectTo,
            callback,
            extra,
            notification,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export function* createHandler(action: CRUDAction) {
  const {
    meta: { resource },
  } = action;
  try {
    const { response } = yield call(handleCRUDAction, action);
    yield put(
      crudActions.create.success({ item: response.data, meta: { resource } }),
    );
    yield call(handleMetaOnSuccess, action);
  } catch (exception) {
    let errors = exception;
    if (exception instanceof HttpError) {
      errors = exception.body;
      if (exception.status === 400) {
        yield put({
          type: 'SIDE_EFFECT',
          meta: {
            notification: {
              level: 'error',
              message: HTTP_400_GENERAL_ERROR_MESSAGE,
            },
          },
        });
      }
    }
    yield put(crudActions.create.failure({ errors, meta: { resource } }));
  }
}

export function* updateHandler(action: CRUDAction) {
  const {
    meta: { resource },
  } = action;
  try {
    const { response } = yield call(handleCRUDAction, action);
    yield put(
      crudActions.update.success({ item: response.data, meta: { resource } }),
    );
    yield call(handleMetaOnSuccess, action);
  } catch (exception) {
    let errors = exception;
    if (exception instanceof HttpError) {
      errors = exception.body;
      if (exception.status === 400) {
        yield put({
          type: 'SIDE_EFFECT',
          meta: {
            notification: {
              level: 'error',
              message: HTTP_400_GENERAL_ERROR_MESSAGE,
            },
          },
        });
      }
    }
    yield put(crudActions.update.failure({ errors, meta: { resource } }));
  }
}

export function* retrieveHandler(action: CRUDAction) {
  const {
    meta: { resource },
  } = action;
  try {
    const { response } = yield call(handleCRUDAction, action);
    yield put(
      crudActions.retrieve.success({ item: response.data, meta: { resource } }),
    );
    yield call(handleMetaOnSuccess, action);
  } catch (error) {
    yield put(crudActions.retrieve.failure({ error, meta: { resource } }));
  }
}

export function* removeHandler(action: CRUDAction) {
  const {
    payload,
    meta: { resource },
  } = action;
  try {
    yield call(handleCRUDAction, action);
    yield put(
      crudActions.remove.success({ item: payload, meta: { resource } }),
    );
    yield call(handleMetaOnSuccess, action);
  } catch (error) {
    yield put(crudActions.remove.failure({ error, meta: { resource } }));
  }
}

const prepareListPayload = (payload: ResourcePayload): Record<string, any> => {
  const {
    filters = {},
    pagination = { page: 1, perPage: PER_PAGE },
    sort = [],
  } = payload;
  const { perPage = PER_PAGE, page = 1 } = pagination;
  const formattedFilters = Object.keys(filters as Record<string, any>).reduce(
    (acc, filterName: string) => {
      const value = filters[filterName];
      if (value === undefined || value === null) return acc;

      if (isObject(value) && !isArray(value)) {
        return {
          ...acc,
          ...Object.keys(value as Record<string, any>).reduce(
            (prebuild, filterKey) => ({
              ...prebuild,
              [`${filterName}__${filterKey}`]: (value as Record<string, any>)[
                filterKey
              ],
            }),
            {} as Record<string, any>,
          ),
        };
      }
      return {
        ...acc,
        [filterName]: value,
      };
    },
    {} as Record<string, any>,
  );

  return {
    limit: perPage,
    offset: (page - 1) * perPage,
    ordering:
      sort && sort.length > 0
        ? sort.map(
            (sortItem: Sort) =>
              `${sortItem.order === 'desc' ? '-' : ''}${sortItem.field}`,
          )
        : undefined,
    ...Object.keys(formattedFilters)
      .filter((queryKey: string) => isValid(formattedFilters[queryKey]))
      .reduce(
        (clearedPayload, queryKey) => ({
          ...clearedPayload,
          [queryKey]: formattedFilters[queryKey],
        }),
        {} as Record<string, any>,
      ),
  };
};

export function* listHandler(action: CRUDAction) {
  const {
    type,
    payload,
    meta: { resource, resetCurrentItem = true },
  } = action;
  const { doRequest = true } = payload;
  try {
    if (doRequest) {
      const { response } = yield call(
        callService,
        resource,
        type,
        prepareListPayload(payload),
      );
      yield put(
        crudActions.list.success({
          ...response.data,
          resetCurrentItem,
          meta: { resource },
        }),
      );
      return;
    }
    yield put(
      crudActions.list.success({
        results: [],
        count: 0,
        resetCurrentItem,
        meta: { resource },
      }),
    );
  } catch (error) {
    yield put(crudActions.list.failure({ error, meta: { resource } }));
  }
}

export function* getManyHandler(action: CRUDAction) {
  const {
    payload,
    meta: { resource },
  } = action;
  try {
    const { response } = yield call(
      callService,
      resource,
      // pretend like list action to get correct service
      crudActions.list.TRIGGER,
      prepareListPayload(payload),
    );
    yield put(
      crudActions.getMany.success({
        ...response.data,
        meta: { resource },
      }),
    );
  } catch (error) {
    yield put(crudActions.getMany.failure({ error, meta: { resource } }));
  }
}
