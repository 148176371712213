import React from 'react';
import MuiPopover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import { BaseInputProps } from 'types';
import { useAnchor } from 'hooks';
import { useInput } from 'contexts';
import FieldHelper from '../FormInputControl/FieldHelper';
import ColorPicker from './ColorPicker';
import Input from '../Input/Input';

const Popover = styled(MuiPopover)(() => ({
  '& .MuiPopover-paper': {
    borderRadius: 0,
  },
}));

const ColorAdornment = styled('div')(() => ({
  border: '1px solid #000',
  width: 20,
  height: 20,
}));

interface ColorPickerInputProps extends BaseInputProps {
  name: string;
  helpText?: string;
  [prop: string]: any;
}

const ColorPickerInput = ({
  classes,
  helpText,
  name,
  rules,
  ...props
}: ColorPickerInputProps) => {
  const { field, error, meta } = useInput({ name, rules });
  const { anchor, onAnchorClick, removeAnchor } = useAnchor<HTMLInputElement>();
  const onColorChange = React.useCallback(
    (color) => {
      field.onChange(color.hex.toUpperCase());
    },
    [field],
  );
  return (
    <React.Fragment>
      <Input
        endAdornment={
          <ColorAdornment style={{ backgroundColor: field.value }} />
        }
        field={field}
        meta={meta}
        error={error}
        onClick={onAnchorClick}
        {...props}
      />
      <FieldHelper meta={meta} helpText={helpText} />
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={removeAnchor}
      >
        <ColorPicker onChange={onColorChange} color={field.value} />
      </Popover>
    </React.Fragment>
  );
};

export default ColorPickerInput;
