import React from 'react';
import { PickersDayProps } from '@mui/lab/PickersDay';

export const LteMode = 'lte';
export const GteMode = 'gte';
export type Mode = 'lte' | 'gte';

export type DateInputOnClick = (
  evt: React.MouseEvent<HTMLInputElement>,
  mode: Mode,
) => any;
export type RenderDay = (
  day: unknown,
  selectedDates: unknown[],
  pickersDayProps: PickersDayProps<unknown>,
) => React.ReactElement;
