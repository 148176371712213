import React from 'react';
import {
  TableCellProps as ReactTableCellProps,
  UseTableCellProps,
} from 'react-table';
import Grid, { GridProps } from 'components/Grid';

export interface TableCellProps extends ReactTableCellProps {
  align: GridProps['justifyContent'];
  cell: UseTableCellProps<any>;
}

const TableCell = ({ align, cell, ...cellProps }: TableCellProps) => (
  <Grid
    item
    container
    zeroMinWidth
    justifyContent={align}
    wrap="nowrap"
    alignItems="center"
    {...cellProps}
  >
    {cell.render('Cell')}
  </Grid>
);

export default TableCell;
