import Immutable from 'immutable';
import resources from 'store/resources';
import usersFilters from 'store/configs/usersFilters';

import { User, ResourceRecord, ResourceReducer } from 'types';
import { createResourceReducer } from './utils';

export type UsersState = ResourceRecord<User>;

export const InitialUsersState = Immutable.Record<ResourceReducer<User>>({
  filtersConf: usersFilters,
  filters: {},
  sort: [],
  isLoading: false,
  count: 0,
  pagination: {
    perPage: 10,
    page: 1,
  },
  currentItem: null,
  autocomplete: [],
  results: [],
  errors: {},
});

export default createResourceReducer({
  resource: resources.USERS,
  initialState: new InitialUsersState(),
});
