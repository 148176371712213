import Immutable from 'immutable';
import HttpError from 'services/HttpError';
import {
  BaseResource,
  Pagination,
  ResourceRecord,
  ResourceReducer,
  Sort,
} from 'types';
import { crudActions, manualActions } from 'store/actions';

const PER_PAGE = 25;

interface Handler<State> {
  (state: State, payload: any, meta?: any): State;
}

interface Action {
  type: string;
  meta: any;
  payload: any;
}

interface ErrorPayload {
  error: HttpError;
}

const convertHttpError = (error: HttpError) => {
  switch (error.status) {
    case 404:
      return 'NOT_FOUND';
    default:
      return error.body;
  }
};

export const createReducer = <
  State extends object,
  ImmutableRecord extends Immutable.Record<State> | Immutable.List<State>,
>(
  handlers: Record<string, Handler<ImmutableRecord>>,
  initialState: ImmutableRecord,
  resource?: string,
) => {
  return (state: ImmutableRecord = initialState, action: Action) => {
    if (action.type) {
      const actionResource = action.meta ? action.meta.resource : null;
      if (actionResource && resource !== actionResource) return state;

      const handler = handlers[action.type];
      if (handler !== null && handler !== undefined) {
        return handlers[action.type](state, action.payload, action.meta);
      }
    }
    return state;
  };
};

interface TriggerParams {
  pagination: Pagination;
  sort: Array<Sort>;
  filters: Record<string, Array<any>>;
}

const applyListTrigger = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { filters, sort, pagination }: TriggerParams,
): ResourceRecord<T> => {
  return state.merge({
    filters,
    pagination,
    sort,
    // errors: {}, ToDo probably requires update here
    isLoading: true,
  });
};

const applyListFailure = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { error }: ErrorPayload,
): ResourceRecord<T> => {
  const errors = {
    http_error: [convertHttpError(error)],
  };
  return state.merge({
    errors: errors,
    isManyLoading: false,
  });
};

const applyListSuccess = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  {
    results,
    count,
    resetCurrentItem = true,
  }: { results: Array<T>; count: number; resetCurrentItem: boolean },
): ResourceRecord<T> => {
  return state.merge({
    results: results,
    currentItem: resetCurrentItem ? null : state.get('currentItem'),
    count,
    isLoading: false,
  });
};

const applyGetManyTrigger = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
): ResourceRecord<T> => {
  return state.merge({
    isManyLoading: true,
  });
};

const applyGetManySuccess = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { results, count }: { results: Array<T>; count: number },
): ResourceRecord<T> => {
  const itemsMap = state.get('results').reduce(
    (prevMap, item) => ({
      ...prevMap,
      [item.id as string]: item,
    }),
    {} as Record<string, T>,
  );
  results.forEach((item) => {
    itemsMap[item.id] = item;
  });
  const mergedResults = Object.values(itemsMap);
  return state.merge({
    results: mergedResults,
    isManyLoading: false,
    count: count > mergedResults.length ? count : mergedResults.length,
  });
};

const applyListAppend = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { results }: { results: Array<T> },
): ResourceRecord<T> => {
  const items = state.get('results').concat(...results);
  return state.merge({
    results: items,
    count: items.length,
  });
};

const applyListRemove = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { item: itemToDelete }: { item: T },
): ResourceRecord<T> => {
  const items = state
    .get('results')
    .filter((item: T) => itemToDelete.id !== item.id);
  return state.merge({
    results: items,
    count: items.length,
  });
};

const applyUpdateInList = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { item }: { item: T },
): ResourceRecord<T> => {
  return state.set(
    'results',
    state.get('results').map((currentItem: T) => {
      return item.id === currentItem.id ? item : currentItem;
    }),
  );
};

const applyDetailTrigger = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
): ResourceRecord<T> => {
  return state.merge({
    errors: {},
    isLoading: true,
    currentItem: null,
  });
};

const applyDetailFailure = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { error }: ErrorPayload,
): ResourceRecord<T> => {
  const errors = {
    http_error: [convertHttpError(error)],
  };
  return state.merge({
    errors,
    isLoading: false,
    currentItem: null,
  });
};

const applyDetailSuccess = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { item }: { item: T },
): ResourceRecord<T> => {
  return state.merge({
    isLoading: false,
    currentItem: item,
  });
};

const applyTrigger = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
): ResourceRecord<T> => {
  return state.merge({
    errors: {},
    isLoading: true,
    isSubmitting: true,
  });
};

const applySuccess = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
): ResourceRecord<T> => {
  return state.merge({
    isLoading: false,
    isSubmitting: false,
  });
};

const applyUpdateSuccess = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { item }: { item: T },
) => {
  return state.merge({
    results: state.get('results').map((cachedItem: T) => {
      return cachedItem.id === item.id ? item : cachedItem;
    }),
    errors: {},
    isLoading: false,
    isSubmitting: false,
    currentItem: item,
  });
};

const applyUpdateFailure = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { errors }: { errors: Record<string, string[]> },
) => {
  return state.merge({
    isLoading: false,
    isSubmitting: false,
    errors,
  });
};

const applyCreateFailure = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { errors }: { errors: Record<string, string[]> },
) => {
  return state.merge({
    isLoading: false,
    isSubmitting: false,
    errors,
  });
};

const applyRemoveSuccess = <
  T extends BaseResource,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  RS extends ResourceReducer<T>,
>(
  state: ResourceRecord<T>,
  { item: itemToDelete }: { item: T },
): ResourceRecord<T> => {
  const items = state
    .get('results')
    .filter((item: T) => itemToDelete.id !== item.id);
  return state.merge({
    results: items,
    count: state.get('count') - 1,
  });
};

export const BaseInitialState = Immutable.Record<ResourceReducer<any>>({
  filtersConf: new Map(),
  filters: {},
  sort: [],
  isLoading: false,
  isSubmitting: false,
  isManyLoading: false,
  count: 0,
  pagination: {
    page: 1,
    perPage: PER_PAGE,
  },
  currentItem: null,
  results: [],
  autocomplete: [],
  errors: {},
});

interface Params<T extends BaseResource> {
  resource: string;
  initialState?: ResourceRecord<T>;
  ignoreCleanup?: boolean;
  handlers?: Record<string, Handler<ResourceRecord<T>>>;
}

export const createResourceReducer = <T extends BaseResource = BaseResource>({
  resource,
  initialState = BaseInitialState(),
  ignoreCleanup = false,
  handlers = {},
}: Params<T>) =>
  createReducer<ResourceReducer<T>, ResourceRecord<T>>(
    {
      [crudActions.list.TRIGGER]: applyListTrigger,
      [crudActions.list.FAILURE]: applyListFailure,
      [crudActions.list.SUCCESS]: applyListSuccess,
      [crudActions.getMany.TRIGGER]: applyGetManyTrigger,
      [crudActions.getMany.SUCCESS]: applyGetManySuccess,
      [crudActions.getMany.FAILURE]: applyListFailure,
      [crudActions.listAppend.TRIGGER]: applyListAppend,
      [crudActions.listRemove.TRIGGER]: applyListRemove,
      [crudActions.retrieve.TRIGGER]: applyDetailTrigger,
      [crudActions.retrieve.FAILURE]: applyDetailFailure,
      [crudActions.retrieve.SUCCESS]: applyDetailSuccess,
      [crudActions.update.TRIGGER]: applyTrigger,
      [crudActions.update.SUCCESS]: applyUpdateSuccess,
      [crudActions.update.FAILURE]: applyUpdateFailure,
      [crudActions.create.TRIGGER]: applyTrigger,
      [crudActions.create.FAILURE]: applyCreateFailure,
      [crudActions.create.SUCCESS]: applySuccess,
      [crudActions.remove.SUCCESS]: applyRemoveSuccess,
      [manualActions.updateInList.TRIGGER]: applyUpdateInList,
      [crudActions.cleanup.TRIGGER]: ignoreCleanup
        ? (state: ResourceRecord<T>) => state
        : (state: ResourceRecord<T>) => state.merge(initialState),
      ...handlers,
    },
    initialState,
    resource,
  );
