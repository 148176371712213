import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppliedFilters, Query, ResourceRecord, Sort } from 'types';

import { ReducerState } from 'store/reducers';
import { getResource } from 'store/selectors/utils';
import { crudActions } from 'store/actions';

import { useReferenceInputController } from './useReferenceInput';

interface ReferenceInputProps {
  children: React.ReactNode;
  source: string;
  name?: string;
  resource: ResourceRecord;
  valueField?: string | null;
  queryField?: string | null;
  perPage?: number;
  debounceTimeout?: number;
  filters?: AppliedFilters;
  sort?: Sort[];
  onChange?: (value: Record<string, any>) => void;
  getList: (query: Query) => void;
  getMany: (query: Query) => void;
  prepareChoices?: (choices: Record<string, any>[]) => Record<string, any>[];
  [prop: string]: any;
}

const ReferenceInput = ({
  filters,
  name,
  valueField = 'id',
  queryField = 'name',
  perPage,
  debounceTimeout,
  resource,
  sort = [],
  getList,
  getMany,
  children,
  onChange,
  prepareChoices,
}: ReferenceInputProps) => {
  const inputProps = useReferenceInputController({
    name,
    debounceTimeout,
    filters,
    valueField,
    queryField,
    perPage,
    resource,
    sort,
    getMany,
    getList,
    onChange,
    prepareChoices,
  });

  return React.cloneElement(children as React.ReactElement, {
    ...(children as React.ReactElement).props,
    ...inputProps,
  });
};

export default connect(
  (
    rootReducer: ReducerState,
    { source }: Pick<ReferenceInputProps, 'source'>,
  ) => ({
    resource: getResource(source)(rootReducer),
  }),
  (dispatch, { source }: Pick<ReferenceInputProps, 'source'>) =>
    bindActionCreators(
      {
        getList: (query: Query) =>
          crudActions.list({
            ...query,
            meta: {
              resource: source,
              resetCurrentItem: false,
            },
          }),
        getMany: (query: Query) =>
          crudActions.getMany({
            ...query,
            meta: {
              resource: source,
            },
          }),
      },
      dispatch,
    ),
)(ReferenceInput);
