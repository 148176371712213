import React from 'react';
import moment, { Moment } from 'moment';
import debounce from 'lodash/debounce';
import MuiInput, { InputProps as BaseInputProps } from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import MuiIconButton from '@mui/material/IconButton';
import { styled, Theme } from '@mui/material/styles';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import config from 'config';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { DateInputOnClick, Mode } from './types';
import DateFormatInput from './DateFormatInput';
import FieldHelper from '../FormInputControl/FieldHelper';

interface Props {
  mode: Mode;
  onClick: DateInputOnClick;
  value: Moment | null;
  onChange: (date: moment.Moment, mode: Mode) => void;
  minValue?: Moment;
  maxValue?: Moment;
}

enum DateError {
  NoError,
  MinError,
  MaxError,
}

export interface InputProps extends BaseInputProps {
  successState: boolean;
  errorState: boolean;
}

const StyledInput = styled(MuiInput, {
  shouldForwardProp: (propName: string) =>
    ['successState', 'errorState'].indexOf(propName) === -1,
})<InputProps>(({ theme, ...ownerState }: InputProps & { theme: Theme }) => ({
  ...(ownerState.successState && {
    '&:before': {
      borderBottomColor: theme.palette.success.main,
    },
  }),
  ...(ownerState.errorState && {
    '&:before': {
      borderBottomColor: theme.palette.error.main,
    },
  }),
  input: {},
  focused: {
    color: `${theme.palette.warning.contrastText} !important`,
    '&:after': {
      borderBottomColor: `${theme.palette.warning.contrastText} !important`,
    },
  },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

const decodeError = (dateError: DateError) => {
  switch (dateError) {
    case DateError.MaxError:
      return 'Date should not be after maximal date';
    case DateError.MinError:
      return 'Date should not be before minimal date';
    default:
      return '';
  }
};

const KeyboardDateInput = ({
  mode,
  value,
  onClick,
  onChange,
  minValue,
  maxValue,
}: Props) => {
  const [dateError, setError] = React.useState<DateError>(DateError.NoError);
  const onClickHandler = React.useCallback(
    (evt) => {
      onClick(evt, mode);
    },
    [mode, onClick],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDebounce = React.useCallback(
    debounce((date: moment.Moment, mode: Mode) => {
      onChange(date, mode);
    }, 3e2),
    [],
  );
  const onInputChange = React.useCallback(
    (evt) => {
      const date = moment(evt.target.value, config.dateFormat);
      if (minValue && date.startOf('day').isBefore(minValue)) {
        setError(DateError.MinError);
        return;
      }
      if (maxValue && date.endOf('day').isAfter(maxValue)) {
        setError(DateError.MaxError);
        return;
      }

      setError(DateError.NoError);
      onChangeDebounce(date, mode);
    },
    [minValue, maxValue, mode, onChangeDebounce, setError],
  );

  const formatValue = React.useMemo(() => {
    if (value) {
      return value.format(config.dateFormat);
    }
    return '';
  }, [value]);
  return (
    <FormControl fullWidth>
      <StyledInput
        autoComplete="false"
        inputComponent={
          DateFormatInput as React.FunctionComponent<InputBaseComponentProps>
        }
        errorState={dateError !== DateError.NoError}
        successState={dateError === DateError.NoError}
        onChange={onInputChange}
        placeholder={config.dateFormat}
        error={dateError !== DateError.NoError}
        endAdornment={
          <IconButton
            title="open-calendar"
            onClick={onClickHandler}
            size="large"
          >
            <CalendarIcon />
          </IconButton>
        }
        value={formatValue}
      />
      <FieldHelper
        meta={{ isTouched: true, invalid: false, isDirty: false }}
        error={decodeError(dateError)}
      />
    </FormControl>
  );
};

export default KeyboardDateInput;
