const USERS = 'users';
const UPLOAD = 'upload';
const SEARCH = 'search';
const SAVED_SEARCHES = 'savedSearches';
const FOLDERS = 'folders';
const SOURCES = 'sources';
const TEAMS = 'teams';
const DRAWER = 'drawer';
const NOTIFICATIONS = 'notifications';
const DATA_SOURCES = 'dataSources';
const DOCUMENT_TYPE = 'documentTypes';
const BURDEN_CLASSES = 'burdenClasses';
const ORGANISATIONS = 'organisations';
const GROUPS = 'groups';
const LICENSES = 'licenses';
const TAGS = 'tags';
const THEMES = 'themes';
const TOPICS = 'topics';
const INDUSTRY = 'industry';
const REGULATORS = 'regulators';
const RECIPIENT_TYPES = 'recipientTypes';
const GOVERNMENT_DEPARTMENTS = 'governmentDepartments';
const SOURCE_GOVERNMENT_DEPARTMENTS = 'sourceGovernmentDepartments';
const GEOGRAPHICAL_EXTENTS = 'geographicalExtents';
const WORKFLOW = 'workflows';
const WEBHOOKS = 'webhooks';
const ACCESSIBILITY = 'accessibility';
const BASKET = 'basket';
const COUNTRIES = 'countries';
const BREACHES_PRIMARY = 'breachesPrimary';
const BREACHES_SECONDARY = 'breachesSecondary';
const BREACHES_SUPPORTING = 'breachesSupporting';
const BREACHES_OBSERVED = 'breachesObserved';
const SANCTIONS = 'sanctions';
const RULES_BREACHES = 'rules_breaches';
const SMF = 'smf';
const SIMF = 'simf';
const CF = 'cf';
const TASKS = 'tasks';
const NODES = 'nodes';
const RELATED_DOCUMENTS = 'relatedDocument';
const RELATED_SEARCH_DETAIL = 'relatedSearchDetail';
const POLICY_MAPPING = 'policyMapping';

const resources = {
  USERS,
  UPLOAD,
  SEARCH,
  SOURCES,
  TEAMS,
  DATA_SOURCES,
  DOCUMENT_TYPE,
  BURDEN_CLASSES,
  DRAWER,
  NOTIFICATIONS,
  ORGANISATIONS,
  GROUPS,
  INDUSTRY,
  LICENSES,
  TASKS,
  TAGS,
  THEMES,
  TOPICS,
  RECIPIENT_TYPES,
  REGULATORS,
  GOVERNMENT_DEPARTMENTS,
  GEOGRAPHICAL_EXTENTS,
  SOURCE_GOVERNMENT_DEPARTMENTS,
  SAVED_SEARCHES,
  FOLDERS,
  SANCTIONS,
  WORKFLOW,
  WEBHOOKS,
  ACCESSIBILITY,
  BASKET,
  COUNTRIES,
  BREACHES_PRIMARY,
  BREACHES_SECONDARY,
  BREACHES_SUPPORTING,
  BREACHES_OBSERVED,
  RULES_BREACHES,
  SMF,
  SIMF,
  CF,
  NODES,
  RELATED_DOCUMENTS,
  RELATED_SEARCH_DETAIL,
  POLICY_MAPPING,
};

export default resources;
