import React from 'react';
import Immutable from 'immutable';
import { createReducer } from './utils';
import { drawerActions } from 'store/actions';

type HeaderType =
  | React.ComponentType<{ title: string; close: () => void }>
  | false
  | undefined;

export interface Drawer {
  title?: string;
  header?: HeaderType;
  isOpen: boolean;
  content: React.ReactElement | null;
}

export const InitialDrawerState = Immutable.Record<Drawer>({
  title: undefined,
  header: undefined,
  isOpen: false,
  content: null,
});

export type DrawerState = Immutable.Record<Drawer>;

const initialState = InitialDrawerState();

const openDrawer = (
  state: DrawerState,
  {
    title,
    header,
    content,
  }: { title: string; content: React.ReactElement; header: HeaderType },
): DrawerState =>
  state.merge({
    title,
    content,
    header,
    isOpen: true,
  });

const closeDrawer = (state: DrawerState): DrawerState =>
  state.merge({
    content: null,
    header: undefined,
    isOpen: false,
  });

const drawerReducer = createReducer<Drawer, DrawerState>(
  {
    // drawer actions
    [drawerActions.showDrawer.TRIGGER]: openDrawer,
    [drawerActions.hideDrawer.TRIGGER]: closeDrawer,
  },
  initialState,
);

export default drawerReducer;
