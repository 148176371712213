import { ReducerState } from 'store/reducers';
import { BaseResource, ResourceRecord, ResourceSelector } from 'types';
import pick from 'lodash/pick';

export const getResource =
  (resourceName: string) => (rootReducer: ReducerState) =>
    rootReducer[resourceName];

export type ResourceGetter<T extends BaseResource> = (
  rootReducer: ReducerState,
) => ResourceRecord<T>;

export const generateResourceSelectors = <T extends BaseResource>(
  resourceName: string,
): ResourceSelector<T> => {
  const resourceGetter = getResource(resourceName) as ResourceGetter<T>;
  return {
    count: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('count'),
    filtersConf: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('filtersConf'),
    filters: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('filters'),
    pagination: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('pagination'),
    sort: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('sort'),
    results: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('results'),
    currentItem: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('currentItem'),
    isLoading: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('isLoading'),
    isSubmitting: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('isSubmitting'),
    errors: (rootReducer: ReducerState) =>
      resourceGetter(rootReducer).get('errors'),
  };
};

export enum Selectors {
  count = 'count',
  filtersConf = 'filtersConf',
  filters = 'filters',
  pagination = 'pagination',
  results = 'results',
  currentItem = 'currentItem',
  isLoading = 'isLoading',
}

type Wrapper<T> = (state: ReducerState) => T;

export const mapProps = <T extends Record<string, any>>(
  resource: string,
  selectors: Array<string>,
): Wrapper<T> => {
  const resourceSelector = generateResourceSelectors(resource);
  const specifiedSelectors = pick(resourceSelector, selectors);
  return (state: ReducerState) =>
    Object.keys(specifiedSelectors).reduce(
      (props, selectorName: string) => ({
        ...props,
        [selectorName]: specifiedSelectors[selectorName](state),
      }),
      {} as T,
    );
};
