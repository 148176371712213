import React from 'react';

interface Props {
  isLoading: boolean;
  component: React.ElementType;
  create: (values: any) => any;
  errors: Record<string, string[]>;
}

export interface ComponentProps {
  isLoading: boolean;
}

const CreateController = ({
  isLoading,
  create,
  component: Component,
  ...restProps
}: Props) => {
  return <Component {...restProps} isLoading={isLoading} onCreate={create} />;
};

export default CreateController;
