import React from 'react';
import ReactDOM from 'react-dom';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'flexmonster/flexmonster.css';

import moment from 'moment';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import { IntercomProvider } from 'react-use-intercom';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CssBaseline from '@mui/material/CssBaseline';

import createStore from 'store';
import config from 'config';
import { PopoverProvider, DrawerProvider } from 'contexts';
import Root from 'containers';
import Modals from 'components/modals';
import ThemeProvider from 'components/Theme';
import Notification from 'layouts/Notification';
import LeftSideDrawer from 'components/LeftSideDrawer';

import * as serviceWorker from './serviceWorker';
import './index.css';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
  invalidDate: 'N/A',
});

const history = createBrowserHistory();
const { store, persistor } = createStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <CssBaseline />
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <IntercomProvider appId={config.intercomAppId}>
              <PopoverProvider>
                <DrawerProvider>
                  <Root />
                  <Modals />
                  <LeftSideDrawer />
                  <Notification />
                </DrawerProvider>
              </PopoverProvider>
            </IntercomProvider>
          </LocalizationProvider>
        </ConnectedRouter>
      </PersistGate>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
