import { FormContext } from 'contexts/Form/FormContext';
import React from 'react';

const useFormValuesControl = () => {
  const {
    getValues,
    setValue,
    reset,
    register,
    errors,
    clearErrors,
    trigger,
    unregister,
  } = React.useContext(FormContext);
  return {
    trigger,
    clearErrors,
    register,
    reset,
    getValues,
    setValue,
    errors,
    unregister,
  };
};

export default useFormValuesControl;
