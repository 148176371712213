import { createRoutine } from './utils';

export const applyFiltersConf = createRoutine('APPLY_SEARCH_FILTERS_CONF');

export interface ApplyFiltersConfPayload {
  [filterName: string]: boolean;
}

const searchActions = {
  applyFiltersConf,
};

export default searchActions;
