import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';

import { useToggle } from 'hooks';
import { ConfirmCallback } from 'hooks/useConfirm';
import { AppliedFilters } from 'types';
import useMany from '../useMany';

interface UseAutocompleteProps {
  optionValue: string;
  optionText: string;
  defaultEmptyOptionText?: string;
  choices: Record<string, any>[];
  setFilterValue?: (value: string) => void;
  currentFilters?: AppliedFilters;
  many?: boolean;
  field: ControllerRenderProps;
  filterOptions: (conf: {
    options: Array<any>;
    inputValue: string;
    getOptionLabel: (value: any) => string;
  }) => any[];
  count?: number;
  setPage?: (page: number) => void;
  page?: number;
  shouldConfirm?: boolean | ((value: number | string) => boolean);
  confirm: (confirm: ConfirmCallback) => void;
}

const useAutocomplete = ({
  optionText,
  optionValue,
  defaultEmptyOptionText,
  field,
  many = false,
  filterOptions,
  choices,
  setFilterValue,
  count,
  setPage,
  page,
  shouldConfirm,
  confirm,
}: UseAutocompleteProps) => {
  const { isMarked: isOpen, toggle } = useToggle();
  const [inputValue, setInputValue] = React.useState<string>('');
  const getOptionLabel = React.useCallback(
    (option: any) => {
      return isString(option)
        ? option
        : get(option, optionText, defaultEmptyOptionText) ||
            defaultEmptyOptionText;
    },
    [optionText, defaultEmptyOptionText],
  );
  const getOptionValue = React.useCallback(
    (option) => {
      return isObject(option) ? get(option, optionValue) : option;
    },
    [optionValue],
  );

  const onChangeOverride = React.useCallback(
    (value) => {
      if (value) {
        const lastIndex = value.length - 1;
        if (
          isFunction(shouldConfirm)
            ? shouldConfirm(value[lastIndex])
            : Boolean(shouldConfirm)
        ) {
          confirm(() => {
            field.onChange(value);
          });
          return;
        }
      }
      field.onChange(value);
    },
    [confirm, field, shouldConfirm],
  );

  const { onChange, value } = useMany({
    field: { ...field, onChange: onChangeOverride },
    many,
    getValue: (evt: any, value: any) => {
      if (getOptionValue(evt) !== undefined) {
        return getOptionValue(evt);
      }
      return getOptionValue(value);
    },
    choices,
    defaultValue: null,
  });
  const onInputChange = React.useCallback(
    (event, newInputValue, reason) => {
      if (reason === 'reset') return;
      if (reason === 'clear') {
        setInputValue('');
        onChange(null);
        return;
      }

      setInputValue(newInputValue);
    },
    [onChange, setInputValue],
  );
  // callbacks
  const onChangeWrapper = React.useCallback(
    (evt, value) => {
      if (value) {
        setInputValue(
          many ? '' : getOptionValue(value) ? getOptionLabel(value) : '',
        );
        onChange(value);
        return;
      }
    },
    [onChange, setInputValue, getOptionLabel, many, getOptionValue],
  );
  const onKeyDown = React.useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Enter') {
        toggle();
        const filteredOptions = filterOptions({
          options: choices,
          inputValue,
          getOptionLabel,
        });
        onChangeWrapper(evt, filteredOptions[0]);
      }
    },
    [
      filterOptions,
      inputValue,
      toggle,
      choices,
      getOptionLabel,
      onChangeWrapper,
    ],
  );

  const open = React.useCallback(() => {
    toggle(true);
  }, [toggle]);

  const close = React.useCallback(() => {
    if (many) {
      setInputValue('');
    }
    toggle(false);
  }, [toggle, setInputValue, many]);

  const loadMore = React.useCallback(() => {
    if (
      count !== undefined &&
      page !== undefined &&
      setPage !== undefined &&
      choices.length < count
    ) {
      setPage(page + 1);
    }
  }, [setPage, page, count, choices]);

  React.useEffect(() => {
    if (field.value && choices && choices.length && !isOpen) {
      const option = choices.find((option: any) =>
        getOptionValue(option)
          ? getOptionValue(option).toString() === field.value.toString()
          : getOptionValue(option) === field.value,
      );
      if (!many && getOptionLabel(option) !== inputValue) {
        setInputValue(getOptionLabel(option));
      }
    }
  }, [
    many,
    choices,
    field.value,
    isOpen,
    inputValue,
    setInputValue,
    getOptionLabel,
    getOptionValue,
  ]);

  React.useEffect(() => {
    if (inputValue && setFilterValue) {
      setFilterValue(inputValue);
    }
  }, [inputValue, setFilterValue]);

  return {
    isOpen,
    value,
    inputValue,
    open,
    close,
    onKeyDown,
    loadMore,
    onChange: onChangeWrapper,
    onInputChange,
    getOptionLabel,
  };
};

export default useAutocomplete;
