import React from 'react';
import { connect } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { bindActionCreators } from 'redux';
import { authActions } from 'store/actions';

import { useAnchor } from 'hooks';
import { AuthState } from 'store/reducers';
import Typography from 'components/Typography';

import Link from 'components/Link';
import { ReactComponent as MenuIcon } from 'assets/menu.svg';
import { capitalize } from '@mui/material/utils';

const MenuBtn = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  '&:focus': {
    backgroundColor: alpha(theme.palette.info.main, 0.04),
  },
}));

const BtnText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.common.black,
  paddingRight: theme.spacing(1),
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  border: `1px solid ${theme.palette.error.main}`,
  padding: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    overflow: 'visible',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-20px',
      left: 'calc(60% - 5px)',
      borderTop: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
      zIndex: 10,
    },
  },
}));

interface ExternalProps {
  firstName: AuthState['first_name'];
  lastName: AuthState['last_name'];
  avatarUrl?: string;
}

interface UserMenuProps extends ExternalProps {
  logout: () => any;
}

const UserMenu = ({
  logout,
  firstName = '',
  lastName = '',
  avatarUrl,
}: UserMenuProps) => {
  const name = `${capitalize(firstName || '')} ${capitalize(lastName || '')}`;
  const { anchor, removeAnchor, onAnchorClick } =
    useAnchor<HTMLButtonElement>();
  const [menuMinWidth, setMenuMinWidthState] = React.useState<number>();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onAnchorClick(event);
    setMenuMinWidthState(event.currentTarget.clientWidth);
  };

  return (
    <React.Fragment>
      <MenuBtn disableRipple onClick={handleClick}>
        <BtnText variant="body1">{name}</BtnText>
        <MenuIcon />
        <UserAvatar alt={name} src={avatarUrl} />
      </MenuBtn>
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={removeAnchor}
        PaperProps={{
          style: {
            minWidth: menuMinWidth,
          },
        }}
      >
        <MenuItem>
          <Link to="/accessibility/profile/">Profile</Link>
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators({ logout: authActions.logout }, dispatch),
)(UserMenu);
