import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MuiPopover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';

import { OperationalData } from 'types';
import { basketActions, modalActions } from 'store/actions';
import { ReducerState } from 'store/reducers';
import { basketSelectors } from 'store/selectors';
import Typography from 'components/Typography';
import BasketPopoverList from './BasketPopoverList';
import BasketPopoverBtns from './BasketPopoverBtns';

const Popover = styled(MuiPopover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(4),
    width: '480px',
    color: theme.palette.warning.contrastText,
  },
}));

export interface BasketPopoverProps {
  anchorEl: Element | null;
  handleClose: () => void;
  removeItemsFromBasket: (p: { documents: OperationalData[] }) => void;
  emptyBasket: () => void;
  documents: OperationalData[];
}

const BasketPopover = ({
  documents,
  anchorEl,
  handleClose,
  removeItemsFromBasket,
  emptyBasket,
}: BasketPopoverProps) => {
  return (
    <Popover
      open={!!anchorEl && anchorEl.getAttribute('aria-label') === 'basket'}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      {documents.length > 0 ? (
        <React.Fragment>
          <BasketPopoverList
            documents={documents}
            removeItems={removeItemsFromBasket}
          />
          <BasketPopoverBtns
            handleClose={handleClose}
            emptyBasket={emptyBasket}
          />
        </React.Fragment>
      ) : (
        <Typography variant="h6" noWrap>
          No items in the basket.
        </Typography>
      )}
    </Popover>
  );
};

export default connect(
  (state: ReducerState) => {
    return {
      documents: basketSelectors.documents(state),
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        removeItemsFromBasket: basketActions.removeItems,
        emptyBasket: modalActions.emptyBasket(dispatch),
      },
      dispatch,
    ),
)(BasketPopover);
