import { AppliedFilters } from 'types';
import React from 'react';
import isEqual from 'lodash/isEqual';

interface UseFilterStateOptions {
  queryField: string | null;
  permanentFilters?: AppliedFilters;
  debounceTime?: number;
}

const useFilterState = ({
  queryField,
  permanentFilters = {},
}: UseFilterStateOptions) => {
  const permanentFiltersProp = React.useRef(permanentFilters);
  const latestValue = React.useRef<string>();
  const [filter, setFilterValue] = React.useState({
    ...permanentFilters,
  });

  const permanentFilterSignature = JSON.stringify(permanentFilters);

  React.useEffect(() => {
    if (
      queryField &&
      !isEqual(permanentFiltersProp.current, permanentFilters)
    ) {
      permanentFiltersProp.current = permanentFilters;
      setFilterValue({
        ...permanentFilters,
        [queryField]: latestValue.current,
      });
    }
  }, [
    permanentFilterSignature,
    permanentFiltersProp,
    permanentFilters,
    queryField,
  ]);

  const setFilter = React.useCallback(
    (value: string) => {
      if (queryField) {
        setFilterValue({
          ...permanentFilters,
          [queryField]: value,
        });
      }
      latestValue.current = value;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permanentFilterSignature, setFilterValue, queryField, latestValue],
  );

  return {
    filter,
    setFilterValue: setFilter,
  };
};

export default useFilterState;
