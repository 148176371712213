import MuiInputLabel, {
  InputLabelProps as MuiInputLabelProps,
} from '@mui/material/InputLabel';
import { styled, Theme } from '@mui/material/styles';

export interface InputLabelProps extends MuiInputLabelProps {
  labelError?: boolean;
  labelSuccess?: boolean;
}

const InputLabel = styled(MuiInputLabel, {
  shouldForwardProp: (propName: string) => {
    return (
      ['focusedView', 'labelError', 'labelSuccess'].indexOf(propName) === -1
    );
  },
})<InputLabelProps>(
  ({ theme, ...ownerState }: InputLabelProps & { theme: Theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, 1.5px) scale(0.75)',
    },
    ...(ownerState.labelError && {
      color: theme.palette.error.main,
    }),
    ...(ownerState.labelSuccess && {
      color: theme.palette.success.main,
    }),
  }),
);

export default InputLabel;
