import { connect } from 'react-redux';
import { ReducerState } from 'store/reducers';
import { authSelectors } from 'store/selectors';
import { Errors, JWTToken } from 'types';

export interface UserDetails {
  auth: JWTToken;
  lastName: string | null;
  accessToken: string | null;
  firstName: string | null;
  email: string | null;
  username: string | null;
  errors: Errors;
  isLoading: boolean;
  isSubmitting: boolean;
  isAuthenticated: boolean;
}

export default connect((state: ReducerState) => ({
  auth: authSelectors.jwtToken(state),
  accessToken: authSelectors.accessToken(state),
  firstName: authSelectors.firstName(state),
  lastName: authSelectors.lastName(state),
  email: authSelectors.email(state),
  username: authSelectors.username(state),
  errors: authSelectors.errors(state),
  isSubmitting: authSelectors.isSubmitting(state),
  isLoading: authSelectors.isLoading(state),
  isAuthenticated: authSelectors.isAuthenticated(state),
}));
