import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AppliedFilters, FilterConf, Pagination, Sort } from 'types';
import { parseRoute, stringifyQuery } from 'utils';
import { debounce } from '@mui/material/utils';

export interface Query {
  filters: AppliedFilters;
  sort: Array<Sort>;
  pagination: Pagination;
}

interface UseListParams extends RouteComponentProps {
  isStatic?: boolean;
  debounceWait?: number;
  perPage?: number;
  baseFilters?: AppliedFilters;
  filtersConf: FilterConf;
  fetch: (values: Record<string, any>) => void;
  isDoFetch: (values: Query) => boolean;
  isLoading: boolean;
  count: number;
}

const useListParams = ({
  baseFilters,
  filtersConf,
  fetch,
  isDoFetch,
  isLoading,
  location,
  history,
  count,
  isStatic = false,
  perPage = 25,
  debounceWait = 500,
}: UseListParams) => {
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
  const [staticQuery, setStaticQuery] = React.useState<Query>({
    filters: {
      ...baseFilters,
    },
    sort: [],
    pagination: {
      perPage,
      page: 1,
    },
  });
  const query = React.useMemo(() => {
    if (isStatic) {
      return staticQuery;
    }
    const { pagination, filters, sort } = parseRoute(
      location,
      filtersConf,
      perPage,
    );
    return {
      filters: {
        ...baseFilters,
        ...filters,
      },
      sort,
      pagination,
    };
  }, [perPage, isStatic, staticQuery, baseFilters, filtersConf, location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetch = React.useCallback(
    debounce(({ filters, pagination, sort }: Query) => {
      fetch({
        filters,
        sort,
        pagination,
        doRequest: isDoFetch({ filters, pagination, sort }),
      });
      setInitialLoading(false);
    }, debounceWait),
    [fetch, setInitialLoading, debounceWait, isDoFetch],
  );

  const changeRoute = React.useCallback(
    (query: Query) => {
      if (isStatic) {
        setStaticQuery(query);
      } else {
        history.replace({
          search: `?${stringifyQuery(query)}`,
        });
      }
      debounceFetch(query);
    },
    [isStatic, setStaticQuery, history, debounceFetch],
  );

  const updateFilters = React.useCallback(
    (filters: AppliedFilters) => {
      changeRoute({
        ...query,
        filters,
        pagination: {
          ...query.pagination,
          page: 1,
        },
      });
    },
    [query, changeRoute],
  );

  const updateSort = React.useCallback(
    (sort: Array<Sort>) => {
      changeRoute({ ...query, sort });
    },
    [query, changeRoute],
  );

  const updatePaginate = React.useCallback(
    (pagination: Pagination) => {
      changeRoute({ ...query, pagination });
    },
    [query, changeRoute],
  );

  React.useEffect(() => {
    if (Object.keys(query).length > 0) {
      debounceFetch(query);
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    const maxPage = Math.ceil(count / perPage);
    if (maxPage !== 0 && maxPage < query.pagination.page) {
      changeRoute({
        ...query,
        pagination: { ...query.pagination, page: maxPage },
      });
    }
  }, [changeRoute, count, perPage, query]);

  return {
    updateFilters,
    updatePaginate,
    updateSort,
    query,
    loading: initialLoading || isLoading,
  };
};

export default useListParams;
