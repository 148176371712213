import get from 'lodash/get';
import moment from 'moment';

const getValue = (name: string, defaultValue: any = null): any => {
  return get(process.env, `REACT_APP_${name}`, defaultValue);
};

export interface Filter {
  name: string;
  label: string;
  enabled: boolean;
  hidden?: boolean;
  type:
    | 'input'
    | 'autocomplete'
    | 'select'
    | 'checkbox'
    | 'radio'
    | 'date'
    | 'datepicker'
    | 'select_input';
  description?: string;
}

export interface FilterGroup {
  name: string;
  filters: Record<string, Filter>;
}

export interface Config {
  appMode: 'dev' | 'prod' | 'test';
  baseUrl: string;
  isProduction: () => boolean;
  dateFormat: string;
  hotJar: {
    id: string | null;
    sv: string | null;
  };
  pusher: {
    appKey: string;
    cluster: string;
  };
  intercomAppId: string;
  alternativeDateFormat: string;
  flexmonsterLicenseKey?: string;
  supportEmail: string;
  dateTimeFormat: string;
  isOpenFinApp: boolean;
  notificationTimeFormat: string;
  dataUploadMaxMemorySize: number;
  minDate: moment.Moment;
  maxDate: moment.Moment;
}

const config: Config = {
  appMode: getValue('MODE', 'dev'),
  baseUrl: getValue('URL', 'https://dev-api.waymark.tech/api/v1/'),
  isProduction: (): boolean => {
    return config.appMode === 'prod';
  },
  supportEmail: getValue('SUPPORT_EMAIL', 'support@waymark.tech'),
  intercomAppId: getValue('INTERCOM_APP_ID', ''),
  hotJar: {
    id: getValue('HOTJAR_ID'),
    sv: getValue('HOTJAR_SNIPPET_VERSION'),
  },
  pusher: {
    appKey: getValue('PUSHER_APP_KEY'),
    cluster: getValue('PUSHER_APP_CLUSTER'),
  },
  flexmonsterLicenseKey: getValue('FLEXMONSTER_LICENSE_KEY', undefined),
  isOpenFinApp: getValue('IS_OPENFIN_APP', false) === true,
  dateFormat: 'DD/MM/YYYY',
  alternativeDateFormat: 'DD.MM.YYYY',
  dateTimeFormat: 'DD.MM.YYYY, HH:mm',
  notificationTimeFormat: 'D MMM',
  dataUploadMaxMemorySize: getValue(
    'DATA_UPLOAD_MAX_MEMORY_SIZE',
    1024 * 1024 * 10,
  ), // 10mb by default
  maxDate: moment(
    getValue('MAX_DATE', `12-31-${new Date().getFullYear() + 1}`),
    'MM-DD-YYYY',
  ),
  minDate: moment(getValue('MIN_DATE', '01-01-2000'), 'MM-DD-YYYY'),
};

export default config;
